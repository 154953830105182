import { User } from '@interfaces/auth.interface';

import api from './';
import { Coupon } from './coupons.api';
import { Payment, PaymentStatus } from './payments.api';

export interface PromotionalPackage {
  id: number;
  name: string;
  coupons: Coupon[];
  paymentStatus: PaymentStatus;
  payments: Payment;
  usersId: number;
  users: User;
  paymentId: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface DataPromotionalSchema {
  name: string;
  data: { name: string; price: number; bibs: number }[];
  data_old: { name: string; price: number; label: string }[];
  totalPrice: number;
  totalPrice_old: number;
}

interface PromotionalPackageCount {
  count: number;
}

export interface PromotionalPackageApiQueryParams {
  page?: number;
  pageLength?: number;
}

const promotionalPackagesGenerate = api.injectEndpoints({
  endpoints: (build) => ({
    getPromotionalPackagesByUser: build.query<PromotionalPackage[], void>({
      query: () => ({
        url: 'promotional-packages/company/user',
      }),
      providesTags: ['packages'],
    }),
    getCompanyPendingPackages: build.query<
      PromotionalPackage[],
      PromotionalPackageApiQueryParams
    >({
      query: (params) => ({
        url: 'promotional-packages/company/pending-packages',
        params,
      }),
      providesTags: ['packages'],
    }),
    getCompanyPendingPackagesCount: build.query<PromotionalPackageCount, void>({
      query: () => ({
        url: 'promotional-packages/company/pending-packages/count',
      }),
      providesTags: ['packages'],
    }),
    getPromotionalPackagesCompany: build.query<DataPromotionalSchema[], void>({
      query: () => ({
        url: 'promotional-packages/company',
      }),
      providesTags: ['packages'],
    }),

    confirmPromotionalPackages: build.mutation<void, number>({
      query: (id) => ({
        url: `promotional-packages/${id}/confirm`,
        method: 'PATCH',
      }),
      invalidatesTags: ['packages'],
    }),
    rejectPromotionalPackages: build.mutation<void, number>({
      query: (id) => ({
        url: `promotional-packages/${id}/reject`,
        method: 'PATCH',
      }),
      invalidatesTags: ['packages'],
    }),
  }),
});

export const {
  useGetPromotionalPackagesByUserQuery,
  useGetPromotionalPackagesCompanyQuery,
  useGetCompanyPendingPackagesQuery,
  useConfirmPromotionalPackagesMutation,
  useRejectPromotionalPackagesMutation,
  useGetCompanyPendingPackagesCountQuery,
} = promotionalPackagesGenerate;
