import 'dayjs/locale/it';

import { useEffect, useState } from 'react';

import {
  Checkbox,
  Divider,
  FileInput,
  Grid,
  Group,
  Select,
  Space,
  Text,
  TextInput,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import {
  IconAddressBook,
  IconFiles,
  IconUpload,
  IconUser,
} from '@tabler/icons-react';

import { FormTypeStrabacino } from '@interfaces/form.interface';

import statesData from '../data/nation.json';
import { requiredCompanyName } from '../utils/forms';
import Icon from './Icon';

interface RegisterFormProps {
  form: FormTypeStrabacino;
  price?: number;
  marathon: string;
}

export default function RegisterFormStrabacino({
  form,
  marathon,
}: RegisterFormProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [isLoading, setIsLoading] = useState(true);

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const selectNationItems = statesData.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [isLoading]);

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Grid>
      {/* ================================== */}
      <Grid.Col span={12}>
        <Group>
          <Icon icon={IconUser} />
          <Text fz="16px" fw={600}>
            Informazioni di base
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - name */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput required label="Nome:" {...form.getInputProps('name')} />
      </Grid.Col>
      {/* ROW - surname */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput
          required
          label="Cognome:"
          {...form.getInputProps('surname')}
        />
      </Grid.Col>
      {/* ROW - birthdate */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <DatePickerInput
          clearable
          required
          locale="it"
          label="Data di nascita:"
          valueFormat="DD/MM/YYYY"
          {...form.getInputProps('birthDate')}
        />
      </Grid.Col>
      {/* ROW - gender */}
      <Grid.Col
        span={{ base: 12, md: 6, lg: requiredCompanyName(marathon) ? 6 : 4 }}
      >
        <Select
          required
          label="Genere:"
          {...form.getInputProps('gender')}
          data={[
            { value: 'male', label: 'Maschio' },
            { value: 'female', label: 'Femmina' },
          ]}
          allowDeselect={false}
        />
      </Grid.Col>
      {/* ROW - email */}
      <Grid.Col
        span={{ base: 12, md: 6, lg: requiredCompanyName(marathon) ? 6 : 4 }}
      >
        <TextInput required label="Email:" {...form.getInputProps('email')} />
      </Grid.Col>
      {/* ROW - phonenumber */}
      <Grid.Col
        span={{ base: 12, md: 6, lg: requiredCompanyName(marathon) ? 6 : 4 }}
      >
        <TextInput
          required
          label="Telefono:"
          {...form.getInputProps('phone')}
        />
      </Grid.Col>
      {requiredCompanyName(marathon) && (
        <>
          {/* ROW - companyName */}
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <TextInput
              label="Nome società:"
              {...form.getInputProps('companyName')}
            />
          </Grid.Col>
        </>
      )}
      <Space h="lg" />
      {/* ================================== */}
      <Grid.Col span={12}>
        <Divider />
        <Space h="md" />
        <Group>
          <Icon icon={IconAddressBook} />
          <Text fz="16px" fw={600}>
            Indirizzo
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - state */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Select
          required
          label="Stato:"
          searchable
          {...form.getInputProps('state')}
          data={selectNationItems}
          allowDeselect={false}
        />
      </Grid.Col>
      {/* ROW - city */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput required label="Città:" {...form.getInputProps('city')} />
      </Grid.Col>
      {/* ROW - province */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput
          required
          label="Provincia (SIGLA):"
          {...form.getInputProps('provincie')}
          maxLength={2}
        />
      </Grid.Col>
      {/* ROW - address */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput
          required
          label="Indirizzo:"
          {...form.getInputProps('address')}
        />
      </Grid.Col>
      {/* ROW - nationality */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput
          required
          label="Nazionalità:"
          {...form.getInputProps('nationality')}
        />
      </Grid.Col>
      {/* ROW - cap */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <TextInput label="CAP:" {...form.getInputProps('cap')} />
      </Grid.Col>

      {/* TAPPE FIDAL */}
      {/* ================================== */}
      {marathon === 'Strabacino competitiva' && (
        <>
          <Grid.Col span={12}>
            <Divider />
            <Space h="md" />
            <Group>
              <Icon icon={IconFiles} />
              <Text fz="16px" fw={600}>
                Documenti
              </Text>
            </Group>
          </Grid.Col>

          <Grid.Col span={12}>
            <Text fz="14px">
              Sono consapevole che se, il giorno dell'evento, non sarò in regola
              con il certificato medico agonistico vidimato e in corso di
              validità per <strong>ATLETICA LEGGERA</strong> o{' '}
              <strong> TRIATLON</strong>, NON potrò prendere parte all'evento e
              NON mi verrà rimborsata la quota di iscrizione.
            </Text>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <FileInput
              {...form.getInputProps('certificateFile')}
              label="Certificato medico (jpeg/pdf, 4MB)"
              leftSection={<Icon icon={IconUpload} size="14px" />}
              accept="image/jpeg,image/jpg,application/pdf"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
            <DatePickerInput
              clearable
              locale="it"
              label="Scadenza certificato:"
              valueFormat="DD/MM/YYYY"
              {...form.getInputProps('certificateDateExpiry')}
            />
          </Grid.Col>
        </>
      )}

      <Grid.Col span={12}>
        <Group mt="sm">
          <Checkbox
            {...form.getInputProps('condition', {
              type: 'checkbox',
            })}
            label="Accetto le condizioni di partecipazione"
          />
        </Group>
      </Grid.Col>
    </Grid>
  );
}
