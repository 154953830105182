import { Button, Group, Text, Title } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { IconCash } from '@tabler/icons-react';

import { PaymentMethods } from '@api/payments.api';
import { PackageType } from '@api/registrations.api';

import CopyBtn from './CopyBtn';

interface ModalBankTransferInfoProps {
  type: PackageType;
  price: number;
  quantity: number;
  onClick: (paymentMethod: PaymentMethods) => void;
}

export default function ModalBankTransferInfo({
  type,
  price,
  quantity,
  onClick,
}: ModalBankTransferInfoProps) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <Title order={3}>Pacchetto {type}</Title>
      <Text mt="lg">
        BENEFICIARIO:{' '}
        <Text span fw={600}>
          SPORT POWER SSD ARL
        </Text>
      </Text>
      <Group>
        <Text>
          IBAN:{' '}
          <Text span fw={600}>
            IT83X0880760790000000065267
          </Text>
        </Text>
        <Text>
          <CopyBtn data="IT83X0880760790000000065267" />
        </Text>
      </Group>
      <Text>
        PREZZO:{' '}
        <Text span fw={600}>
          {price.toLocaleString('it-IT', {
            style: 'currency',
            currency: 'EUR',
          })}
        </Text>
      </Text>
      <Text mb="lg">
        PETTORALI:{' '}
        <Text span fw={600}>
          {quantity}
        </Text>
      </Text>

      <Text fz="0.8rem">
        Dopo aver confermato l'ordine, riceverete un'email di conferma
        dell'avvenuta ricezione. Il vostro ordine sarà messo in stand by e verrà
        confermato una volta ricevuto il bonifico. Quando il pagamento sarà
        confermato, riceverete un'email con la lista dei coupon. Ogni coupon
        potrà essere passato ai singoli atleti, che potranno iscriversi
        autonomamente utilizzando il codice coupon, senza alcun costo aggiuntivo
        (poiché già coperto dal vostro pagamento). Una volta che l'atleta avrà
        completato l'iscrizione, voi, in qualità di società, dovrete confermare
        la sua registrazione tramite la vostra area riservata.
      </Text>
      <Group mt="xl" justify="end">
        <Button variant="default" onClick={() => closeAllModals()}>
          Annulla
        </Button>
        <Button
          type="submit"
          leftSection={<IconCash />}
          onClick={() => {
            onClick('bacs');
          }}
        >
          Conferma ordine
        </Button>
      </Group>
    </>
  );
}
