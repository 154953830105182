import { Link, Navigate, useNavigate } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  Paper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import { usePasswordResetRequestMutation } from '@api/passwordReset.api';

import useAuth from '@hooks/useAuth';

import { handleSubmitError } from '../utils/forms';
import Layout from './layout/Layout';

export default function PasswordResetRequest() {
  // ==========================================================================
  // General
  // ==========================================================================

  const { user } = useAuth();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const [passwordResetRequest, { isLoading }] =
    usePasswordResetRequestMutation();
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordResetRequest(values).unwrap();

      showNotification({
        title: 'Richiesta ripristino effettuata',
        message:
          "Se l'email inserita è corretta riceverai un messaggio con un link per reimpostare la tua password",
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };
  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return user ? (
    <Navigate to="/ordini" replace={true} />
  ) : (
    <Layout>
      <Container size={420} my={120}>
        <Paper p={30} mt={30}>
          <form
            onSubmit={form.onSubmit((values) => {
              onSubmit(values);
            })}
          >
            <Title order={2} ta="center" mb="lg">
              Recupera password
            </Title>
            <Text>
              Inserisci l'email con la quale ti sei registrato e riceverai un
              link per ripristinare la tua password.
            </Text>

            <TextInput
              label="Email"
              placeholder="email@example.com"
              required
              {...form.getInputProps('email')}
            />
            {form.errors.general && (
              <Text color="red" p="8px">
                {form.errors.general}
              </Text>
            )}

            <Button type="submit" fullWidth mt="xl" loading={isLoading}>
              Recupera password
            </Button>
            <Text c="dimmed" fz="0.8rem" ta="end">
              Sai già la tua password?{' '}
              <Anchor to="/" component={Link} fz="0.8rem" c="dimmed">
                Accedi
              </Anchor>
            </Text>
          </form>
        </Paper>
      </Container>
    </Layout>
  );
}
