import { Anchor, Button, Group, Text } from '@mantine/core';

import Layout from '@components/layout/Layout';

import classes from './NotFound.module.css';

export default function NotFound() {
  return (
    <Layout>
      <Group justify="center">
        <div>
          <Text className={classes.notfound__containertitle}>404</Text>
          <Group>
            <Text className={classes.notfound__containerdescription}>
              Pagina non trovata
            </Text>
            <Button>
              <Anchor href="https://m6c.it/" underline="never">
                Torna al sito
              </Anchor>
            </Button>
          </Group>
        </div>
      </Group>
    </Layout>
  );
}
