import { FC, useState } from 'react';

import { Button, Center, Loader, Text } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { IconCheck, IconCreditCard } from '@tabler/icons-react';

interface PaymentFormProps {
  total: number;
  onSuccess: () => void;
}

const PaymentForm: FC<PaymentFormProps> = ({ total, onSuccess }) => {
  // ==========================================================================
  // General
  // ==========================================================================
  const stripe = useStripe();
  const elements = useElements();
  // ==========================================================================
  // State
  // ==========================================================================

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // // return_url: 'http://localhost:3000',
      },
      redirect: 'if_required',
    });

    if (error) {
      if (error.type === 'card_error' || error.type === 'validation_error') {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error.message);
      }
    } else {
      switch (paymentIntent?.status) {
        case 'succeeded':
          showNotification({
            title: 'Pagamento confermato',
            message: 'Riceverai una mail di conferma',
            icon: <IconCheck />,
            color: 'teal',
          });

          onSuccess();

          break;
        case 'processing':
          setErrorMessage('Il pagamento è in esecuzione.');
          break;
        case 'requires_payment_method':
          setErrorMessage('Pagamento fallito.');
          break;
        default:
          setErrorMessage('Errore inaspettato.');
          break;
      }
    }

    setIsLoading(false);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <p>
        <strong>Totale: </strong>
        {Number((total / 100).toFixed(2)).toLocaleString('it-IT', {
          style: 'currency',
          currency: 'EUR',
        })}
      </p>
      {!stripe || !elements ? (
        <Center>
          <Loader />
        </Center>
      ) : (
        <PaymentElement />
      )}
      {errorMessage && (
        <Text color="red" size="sm" mt="xl">
          {errorMessage}
        </Text>
      )}
      <Button
        leftSection={<IconCreditCard />}
        loading={isLoading || !stripe || !elements}
        type="submit"
        mt="md"
      >
        Paga ora
      </Button>
    </form>
  );
};

export default PaymentForm;
