export const dateToDateString = (date: Date): string => {
  return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
};

export const dateToHourString = (date: Date): string => {
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

export const dateToDateHourseString = (date: string): string => {
  const dateObj = new Date(date);

  return `${dateToNormaFormat(date)} - ${dateToHourString(dateObj)}`;
};

export const dateToNormaFormat = (dateStr: string): string => {
  // Crea un oggetto Date dalla stringa fornita
  const dateObj = new Date(dateStr);

  // Estrai giorno, mese e anno dalla data
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = String(dateObj.getFullYear());

  // Unisci i componenti per formare la data nel formato "dd/mm/yyyy"
  return `${day}/${month}/${year}`;
};
