import api from './';
import { CouponMarathon } from './couponMarathon.api';
import { PromotionalPackage } from './promotionalPackages.api';
import { SortBy, SortOrder } from './registrations.api';

export type CouponPriceType = 'absolute' | 'relative';
export type SubscribersGender = 'male' | 'female';
export type TargetGender = 'all' | 'male' | 'female';

export interface Coupon {
  code: string;
  promotionalPackageId: number;
  promotionalPackages: PromotionalPackage;
  startDate: string;
  endDate: string | null;
  couponMarathons: CouponMarathon[];
  couponPriceType: CouponPriceType | null;
  targetGender: SubscribersGender | null;
  quantity: number | null;
  createdOperator: boolean | null;
  createdAt: string;
}

export interface SetPriceMarathon {
  marathon: string;
  price: number;
}

export interface GenerateCouponRequest {
  code: string;
  startDate: Date;
  endDate: Date | null;
  marathons: string[];
  marathonPrices: number[];
  couponPriceType: CouponPriceType;
  targetGender: TargetGender;
  quantity: number | null;
}

export interface CouponWithCouponMarathonAndPromotionalPackage extends Coupon {
  promotionalPackage: PromotionalPackage | null;
  couponMarathons: CouponMarathon[];
}

export interface CouponsApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
}

export interface CouponsApiCountParams {
  searchQuery?: string;
}

interface CouponCount {
  count: number;
}

const couponsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findManyCouponsByCompany: builder.query<Coupon[], number>({
      query: (id) => ({
        url: `coupons/company/${id}`,
      }),
      providesTags: ['coupons'],
    }),
    findManyCoupons: builder.query<
      CouponWithCouponMarathonAndPromotionalPackage[],
      CouponsApiQueryParams
    >({
      query: (params) => ({
        url: 'coupons',
        params,
      }),
      providesTags: ['coupons'],
    }),
    countCoupon: builder.query<CouponCount, CouponsApiCountParams>({
      query: (params) => ({
        url: 'coupons/count',
        params,
      }),
      providesTags: ['coupons'],
    }),
    generateCodeOperator: builder.mutation<void, GenerateCouponRequest>({
      query: (body) => ({
        url: 'coupons/generate-operator',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['coupons'],
    }),
    removeCoupon: builder.mutation<Coupon, string>({
      query: (id) => ({
        url: `coupons/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['coupons'],
    }),
  }),
});

export const {
  useFindManyCouponsByCompanyQuery,
  useCountCouponQuery,
  useFindManyCouponsQuery,
  useGenerateCodeOperatorMutation,
  useRemoveCouponMutation,
} = couponsApi;
