import {
  RequestUserActivationRequest,
  User,
  UserCreateRequest,
  UserPatchRequest,
} from '@interfaces/auth.interface';

import api from './';

export const {
  useRequestActivationMutation,
  useActivateMutation,
  usePatchUserMutation,
  useCreateCompanyUserMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    requestActivation: build.mutation<void, RequestUserActivationRequest>({
      query: (body) => ({
        url: `users/activate/request`,
        method: 'POST',
        body,
      }),
    }),
    activate: build.mutation<void, string>({
      query: (token) => ({
        url: `users/activate/${token}`,
        method: 'POST',
      }),
    }),
    patchUser: build.mutation<User, UserPatchRequest>({
      query: (body) => ({
        url: 'users',
        method: 'PATCH',
        body,
      }),
    }),
    createCompanyUser: build.mutation<User, UserCreateRequest>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        body,
      }),
    }),
  }),
});
