import { useEffect } from 'react';

const useScript = (src: string, options = { defer: true }) => {
  return useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = options.defer;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src, options]);
};

export default useScript;
