import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { UserRole } from '@interfaces/auth.interface';

import { selectCurrentUser } from '@slices/auth.slice';

const useUserRoles = (permission: UserRole) => {
  const user = useSelector(selectCurrentUser);

  const hasPermission = user?.role.includes(permission);

  return useMemo(() => hasPermission, [hasPermission]);
};

export default useUserRoles;
