import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  Container,
  LoadingOverlay,
  Paper,
  PasswordInput,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';

import {
  useCheckTokenPasswordQuery,
  usePasswordResetMutation,
} from '@api/passwordReset.api';

import { handleSubmitError } from '../utils/forms';
import Layout from './layout/Layout';

export default function PasswordReset() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { token } = useParams();

  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const { isError, isLoading: isLoadingCheckToken } =
    useCheckTokenPasswordQuery(token!);

  const [passwordReset, { isLoading }] = usePasswordResetMutation();
  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      passwordConfirm: (value, values) =>
        value !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await passwordReset({
        token: token!,
        body: { password: values.password },
      }).unwrap();

      showNotification({
        title: 'Password modificata',
        message: 'La tua password è stata reimpostata con successo',
      });

      navigate('/');
    } catch (e) {
      handleSubmitError(e, form);
    }
  };
  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout>
      <LoadingOverlay visible={isLoadingCheckToken} />
      <Container size={420} my={120}>
        <Paper p={30} mt={30}>
          {isError ? (
            <>
              <Text>Link di ripristino password scaduto.</Text>
              <Anchor to="/password-reset" component={Link}>
                Richiedine uno nuovo
              </Anchor>
            </>
          ) : (
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title order={2} ta="center" mb="sm">
                Reset della password
              </Title>
              <Text>Inserisci una nuova password.</Text>
              <PasswordInput
                label="Nuova password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('password')}
              />
              <PasswordInput
                mt="xs"
                label="Ripeti password"
                placeholder="La tua nuova password"
                required
                {...form.getInputProps('passwordConfirm')}
              />
              {form.errors.general && (
                <Text c="red" p="8px">
                  {form.errors.general}
                </Text>
              )}
              <Button type="submit" fullWidth mt="xl" loading={isLoading}>
                Conferma
              </Button>
              <Text c="dimmed" fz="0.8rem" ta="end">
                Sai già la tua password?{' '}
                <Anchor to="/" component={Link} c="dimmed" fz="0.8rem">
                  Accedi
                </Anchor>
              </Text>
            </form>
          )}
        </Paper>
      </Container>
    </Layout>
  );
}
