// interface ListSubscribersCompanyProps {}

import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import {
  Anchor,
  Button,
  Grid,
  Group,
  Menu,
  Select,
  Text,
  Tooltip,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCalendar,
  IconCircleCheck,
  IconClockExclamation,
  IconGiftCard,
  IconInfoCircle,
  IconMail,
  IconPhone,
  IconRoute,
  IconScan,
  IconTools,
  IconUser,
  IconXboxX,
} from '@tabler/icons-react';

import { useFindManyCouponsByCompanyQuery } from '@api/coupons.api';
import { useGetMarathonsQuery } from '@api/marathons.api';
import {
  MarathonType,
  Registration,
  RegistrationsApiQueryParams,
  SortBy,
  SortOrder,
  SubscribersGender,
  useConfirmRegistrationMutation,
  useCountRegistrationsByPacakgeQuery,
  useGetRegistrationsByPacakgeQuery,
  useRejectRegistrationMutation,
} from '@api/registrations.api';
import { Subscriber } from '@api/subscribers.api';

import Icon from '@components/Icon';
import Layout from '@components/layout/Layout';
import ModalListCoupon from '@components/ModalListCoupon';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';
import SortableTable, {
  TableData,
} from '@components/sortableTable/SortableTable';

import { dateToDateString } from '../../utils/date';
import {
  formatMarathonOption,
  labelGender,
  labelMarathon,
} from '../../utils/mapLabel';
import { capitalizeString } from '../../utils/text';

export default function ListSubscribersCompany() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const [filters, setFilters] = useState<RegistrationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
    marathon: searchParams.get('marathon')
      ? (searchParams.get('marathon') as MarathonType)
      : undefined,
    gender: searchParams.get('gender')
      ? (searchParams.get('gender') as SubscribersGender)
      : undefined,
    coupon: searchParams.get('coupon')
      ? (searchParams.get('coupon') as string)
      : undefined,
  });
  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  // Get All registrations
  const { data = [], isLoading: isLoadingRegistrations } =
    useGetRegistrationsByPacakgeQuery(
      {
        promotionalPackageId: +id!,
        params: {
          ...filters,
          searchQuery,
        },
      },
      { refetchOnMountOrArgChange: true },
    );

  // Get count registrations
  const {
    data: registrationsCount = { count: 0 },
    isLoading: isLoadingRegistrationsCount,
  } = useCountRegistrationsByPacakgeQuery({
    promotionalPackageId: +id!,
    params: {
      marathon: filters.marathon,
      gender: filters.gender,
      searchQuery,
    },
  });
  // Confirm registration
  const [confirmRegistration] = useConfirmRegistrationMutation();

  // Reject registration
  const [rejectRegistration] = useRejectRegistrationMutation();

  // Get marathons
  const { data: marathons = [], isLoading: isLoadingMarathons } =
    useGetMarathonsQuery({});

  // Get list coupons
  const { data: couponsList = [], isLoading: isLoadingCouponsByCompany } =
    useFindManyCouponsByCompanyQuery(+id!);

  // Map registrations to table
  const registrations: TableData[] = [];

  data.map((reg: Registration) => {
    reg.subscribers.map((sub: Subscriber) => {
      const dataFormat = dateToDateString(new Date(sub.birthDate));

      const data = [
        sub.name,
        sub.surname,
        sub.email,
        sub.phone,
        labelMarathon(reg.marathonName),
        labelGender(sub.gender),
        dataFormat,
        reg.payment?.couponCode,
        reg.registrationStatus === 'confirmed' ? (
          <Tooltip label="Registrazione confermata">
            <Icon icon={IconCircleCheck} color="#44ce1b" />
          </Tooltip>
        ) : reg.registrationStatus === 'rejected' ? (
          <Tooltip label="Registrazione respinta">
            <Icon icon={IconXboxX} color="#e51f1f" />
          </Tooltip>
        ) : (
          <Tooltip label="Registrazione in attesa">
            <Icon icon={IconClockExclamation} color="#0064a6" />
          </Tooltip>
        ),
        <Group justify="right">
          {reg.registrationStatus === 'pending' ? (
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <Button size="xs" leftSection={<Icon icon={IconTools} />}>
                  Gestisci
                </Button>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Label>Gestisci iscrizione</Menu.Label>

                <Menu.Item
                  leftSection={<Icon icon={IconCircleCheck} />}
                  onClick={async () => {
                    openConfirmModal({
                      title: 'Conferma iscrizione',
                      children: (
                        <Text fz="sm">
                          Stai per confermare l'iscritto{' '}
                          <span style={{ fontWeight: 600 }}>
                            {capitalizeString(sub.name)}{' '}
                            {capitalizeString(sub.surname)}.{' '}
                          </span>
                          Sicuro di voler procedere?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Conferma iscrizione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await confirmRegistration(reg.id).unwrap();
                          showNotification({
                            color: 'green',
                            title: 'Successo',
                            message: 'Iscritto confermato con successo',
                          });
                        } catch (e) {
                          showNotification({
                            color: 'red',
                            title: 'Errore',
                            message: "Errore nella gestione dell'iscritto",
                          });
                        }
                      },
                    });
                  }}
                >
                  Conferma
                </Menu.Item>
                <Menu.Item
                  leftSection={<Icon icon={IconXboxX} />}
                  onClick={async () => {
                    openConfirmModal({
                      title: 'Respingi iscrizione',
                      children: (
                        <Text fz="sm">
                          Stai per rifiutare l'iscritto{' '}
                          <span style={{ fontWeight: 600 }}>
                            {capitalizeString(sub.name)}{' '}
                            {capitalizeString(sub.surname)}.{' '}
                          </span>
                          Sicuro di voler procedere?
                        </Text>
                      ),
                      labels: {
                        confirm: 'Respingi iscrizione',
                        cancel: 'Annulla',
                      },
                      confirmProps: { color: 'red' },
                      onConfirm: async () => {
                        try {
                          await rejectRegistration(reg.id).unwrap();
                          showNotification({
                            color: 'green',
                            title: 'Successo',
                            message: 'Iscritto respinto con successo',
                          });
                        } catch (e) {
                          showNotification({
                            color: 'red',
                            title: 'Errore',
                            message: "Errore nella gestione dell'iscritto",
                          });
                        }
                      },
                    });
                  }}
                >
                  Respingi
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ) : (
            <Tooltip label="Registrazione gestita">
              <Button
                size="xs"
                leftSection={<Icon icon={IconCircleCheck} />}
                disabled={true}
              >
                Gestisci
              </Button>
            </Tooltip>
          )}
          <Button
            size="xs"
            onClick={async () => {
              openModal({
                title: (
                  <Text>
                    Dettaglio iscritto{' '}
                    <span style={{ fontWeight: 600 }}>
                      {capitalizeString(sub.name)}{' '}
                      {capitalizeString(sub.surname)}
                    </span>
                    {sub.cardNumber && (
                      <Text fz={'12px'}>
                        Numero carta:{' '}
                        <span style={{ fontWeight: 600 }}>
                          {sub.cardNumber}
                        </span>{' '}
                      </Text>
                    )}
                  </Text>
                ),
                children: (
                  <>
                    <Grid gutter={25} mt="sx">
                      {/* ROW */}
                      <Grid.Col span={6}>
                        <Group mb="lg">
                          <Icon icon={IconCalendar} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Data di nascita
                            </Text>
                            <Text size="sm">{dataFormat} </Text>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconRoute} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} fz="sm">
                              Tappa
                            </Text>
                            <Text size="sm">
                              {' '}
                              {labelMarathon(reg.marathonName)}
                              {reg.marathonName === 'StaffettaMiTi'
                                ? ' / ' +
                                  (sub.raceLength === 'l_32km'
                                    ? '32km'
                                    : '10km')
                                : ''}
                            </Text>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconUser} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Genere
                            </Text>
                            <Text fz="sm">{labelGender(sub.gender)}</Text>
                          </div>
                        </Group>
                      </Grid.Col>
                      {/* ROW */}
                      <Grid.Col span={6}>
                        <Group mb="lg">
                          <Icon icon={IconPhone} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} fz="sm">
                              Telefono
                            </Text>
                            <Anchor
                              href={`tel:${sub?.phone}`}
                              fz="sm"
                              underline="never"
                              c="#000"
                            >
                              {sub.phone}
                            </Anchor>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconMail} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Email
                            </Text>
                            <Anchor
                              href={`mailto:${sub.email}`}
                              size="sm"
                              underline="never"
                              c="#000"
                            >
                              {sub.email}
                            </Anchor>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconScan} size="25px" />

                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Codice identificativo
                            </Text>
                            <Text fz="sm">{reg.identificationCode}</Text>
                          </div>
                        </Group>
                      </Grid.Col>
                    </Grid>
                  </>
                ),
                size: '40%',
              });
            }}
            leftSection={<Icon icon={IconInfoCircle} />}
          >
            Dettagli
          </Button>
        </Group>,
      ];

      registrations.push({
        key: sub.id,
        data,
      });
    });
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  // Select filter by marathon
  const onSubscribersMarathonChange = (value: string | null) => {
    searchParams.set('marathon', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      marathon: value as MarathonType,
    });
  };

  const marathonFilter = (
    <Select
      allowDeselect={false}
      key="marathon filter"
      value={filters.marathon}
      onChange={onSubscribersMarathonChange}
      placeholder="Filtro percorso"
      data={[
        { label: 'Tutti', value: 'all' },
        ...marathons.map(formatMarathonOption),
      ]}
    />
  );

  // Select filter by gender
  const onSubscribersGenderChange = (value: string | null) => {
    searchParams.set('gender', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      gender: value! as SubscribersGender,
    });
  };

  const genderFilter = (
    <Select
      allowDeselect={false}
      key="category filter"
      value={filters.gender}
      onChange={onSubscribersGenderChange}
      placeholder="Filtro genere"
      data={[
        { label: 'Tutti', value: 'all' },
        { label: 'Maschio', value: 'male' },
        { label: 'Femmina', value: 'female' },
      ]}
    />
  );

  // Select filter by coupon
  const onSubscribersCouponChange = (value: string | null) => {
    searchParams.set('coupon', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      coupon: value as string,
    });
  };

  const couponFilter = (
    <Select
      allowDeselect={false}
      style={{ width: '14rem' }}
      key="coupon filter"
      value={filters.coupon}
      onChange={onSubscribersCouponChange}
      placeholder="Filtro coupon"
      data={[
        { label: 'Tutti', value: 'all' },
        ...couponsList.map((c) => ({ label: c.code, value: c.code })),
      ]}
    />
  );

  // UseEffect loading
  useEffect(() => {
    if (
      (isLoadingMarathons ||
        isLoadingRegistrations ||
        isLoadingCouponsByCompany) &&
      !isLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [
    isLoading,
    isLoadingCouponsByCompany,
    isLoadingMarathons,
    isLoadingRegistrations,
  ]);

  const handleOpenModalCoupons = () => {
    openModal({
      title: <Text>Coupons</Text>,
      children: <ModalListCoupon data={couponsList} />,
      size: 'auto',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(registrationsCount.count / filters.pageLength!);

  return (
    <Layout
      title={`Iscritti pacchetto #${id!}`}
      backLink={{ title: 'Torna alla lista pacchetti', to: '/' }}
      actionButtons={[
        {
          label: 'Coupon generati',
          onClick: () => {
            handleOpenModalCoupons();
          },
          icon: IconGiftCard,
        },
      ]}
    >
      <SearchAddContainer
        additionalFilters={[marathonFilter, genderFilter, couponFilter]}
        searchPlaceholder="Ricerca per nome, cognome o email"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <SortableTable
          data={registrations}
          headings={{
            nome: 'Nome',
            cognome: 'Cognome',
            email: 'Email',
            phone: 'Telefono',
            percorso: 'Percorso',
            genere: 'Genere',
            birthDate: 'Data di nascita',
            coupon: 'Coupon',
            registrationStatus: 'Stato registrazione',
            actionsIcon: '',
          }}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as SortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun iscritto trovato"
          loading={isLoading || isLoadingRegistrationsCount}
        />
        {registrations.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </Layout>
  );
}
