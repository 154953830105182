const env = {
  MODE: import.meta.env.MODE,
  API_URL: import.meta.env.VITE_API_URL
    ? import.meta.env.VITE_API_URL
    : `${window.location.protocol}//${window.location.hostname}:5000/api`,
  API_LOGIN_PATH: import.meta.env.VITE_LOGIN_PATH || 'auth/signin',
  API_REFRESH_PATH: import.meta.env.VITE_LOGIN_PATH || 'auth/refresh',
  WS_URL:
    import.meta.env.VITE_WS_URL ||
    `${window.location.protocol}//${window.location.hostname}:5000`,
  STRIPE_PUBLIC_KEY: import.meta.env.VITE_STRIPE_PUBLIC_KEY || '',
  RECAPTCHA_PUBLIC_KEY: import.meta.env.VITE_RECAPTCHA_PUBLIC_KEY || '',
  HIGHLIGHT_PROJECT_ID: import.meta.env.VITE_HIGHLIGHT_PROJECT_ID,
  API_DOMAIN: import.meta.env.VITE_API_DOMAIN,
};

export default env;
