import { Anchor, createTheme } from '@mantine/core';

const theme = createTheme({
  fontFamily: 'Poppins, sans-serif',

  headings: {
    fontFamily: 'Poppins, sans-serif',
  },

  colors: {
    brand: [
      '#ff9999',
      '#ff8080',
      '#ff6666',
      '#ff4d4d',
      '#ff3333',
      '#ff1a1a',
      '#ff0000',
      '#e60000',
      '#cc0000',
      '#b30000',
    ],
  },
  primaryColor: 'brand',

  defaultRadius: 'md',

  components: {
    Anchor: Anchor.extend({
      styles: { root: { color: '#fff' } },
    }),
  },
});

export default theme;
