import { ReactNode } from 'react';

import { UserRole } from '@interfaces/auth.interface';

import useAuth from '@hooks/useAuth';

import NotFound from '@routes/notFound/NotFound';

import PrivateRoute from './PrivateRoute';

interface RoleSpecificRouteProps {
  role: UserRole;
  element: ReactNode;
}

export default function RoleSpecificRoute({
  role,
  element,
}: RoleSpecificRouteProps) {
  const { user } = useAuth();

  return (
    <PrivateRoute element={user?.role !== role ? <NotFound /> : element} />
  );
}
