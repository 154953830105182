import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  Button,
  Container,
  Group,
  Loader,
  Paper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { isApiError } from '@api/index';
import {
  useActivateMutation,
  useRequestActivationMutation,
} from '@api/users.api';

import Layout from '@components/layout/Layout';

export default function ActivateUser() {
  const { token } = useParams();

  const [activateUser, { isLoading, error }] = useActivateMutation();
  const [requestActivation, { isLoading: isLoadingRequestActivation }] =
    useRequestActivationMutation();

  useEffect(() => {
    const f = async () => {
      await activateUser(token!);
    };

    f();
  }, [activateUser, token]);

  const initialValues = {
    email: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await requestActivation(values).unwrap();
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else {
          form.setErrors({
            general: 'Unexpected error. Please try again.',
          });
        }
      }
    }
  };

  return (
    <Layout>
      <Group justify="center">
        {isLoading ? (
          <Loader />
        ) : error && isApiError(error) ? (
          <>
            <Container size={420}>
              <Paper p={30} mt={30}>
                <form onSubmit={form.onSubmit(onSubmit)}>
                  <Title order={2} ta="center" mb="lg">
                    Errore link
                  </Title>
                  <Text mb="lg">
                    Link di creazione password non valido o scaduto.
                  </Text>
                  <TextInput
                    required
                    label="Email"
                    placeholder="example@email.com"
                    {...form.getInputProps('email')}
                    radius="md"
                  />
                  <Button
                    type="submit"
                    style={{ width: '100%' }}
                    mt="md"
                    loading={isLoadingRequestActivation}
                  >
                    Manda email
                  </Button>
                </form>
              </Paper>
            </Container>
          </>
        ) : (
          <Container size={420}>
            <Paper p={30} mt={30}>
              <Title order={2} ta="center" mb="lg">
                Account attivato
              </Title>
              <Text mb="lg">Ora puoi accedere alla tua area riservata.</Text>
              <Button
                type="submit"
                style={{ width: '100%' }}
                mt="md"
                onClick={() => {
                  window.location.href = `/login`;
                }}
              >
                Login
              </Button>
            </Paper>
          </Container>
        )}
      </Group>
    </Layout>
  );
}
