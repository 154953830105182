import api from './';
import { MarathonType, SubscribersGender } from './registrations.api';

export interface PriceByCouponRequest {
  marathon: MarathonType;
  code: string;
  targetGender: SubscribersGender[];
}

export interface PriceByCouponResponse {
  price: number;
  isCouponValid: boolean;
}

export interface CouponMarathon {
  couponId: string;
  marathonId: string;
  price: number;
  quantity: number | null;
}

const couponsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    checkCoupon: builder.query<PriceByCouponResponse, PriceByCouponRequest>({
      query: (params) => ({
        url: 'coupon-marathon/price',
        params,
      }),
    }),
  }),
});

export const { useLazyCheckCouponQuery } = couponsApi;
