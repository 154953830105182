import api from './';
import { Coupon } from './coupons.api';
import { DataPromotionalSchema } from './promotionalPackages.api';
import { Registration } from './registrations.api';

export type PaymentStatus = 'inProgress' | 'completed' | 'failed';
export type PaymentMethods = 'bacs' | 'stripe';

export interface buyPackageRequest {
  name: string;
  paymentMethods: PaymentMethods | null;
}

export interface PaymentsPackage {
  id: number;
  stripeClientSecret: string | null;
  paymentStatus: PaymentStatus;
  package: DataPromotionalSchema;
}

export interface Payment {
  id: number;
  stripeClientSecret: string | null;
  paymentStatus: PaymentStatus;
  paymentMethods: PaymentMethods;
  couponCode: string | null;
  total: number | null;
  coupons: Coupon | null;
  registration: Registration;
  createdAt: Date;
  updatedAt: Date;
}

const paymentsGenerate = api.injectEndpoints({
  endpoints: (build) => ({
    buyPackage: build.mutation<PaymentsPackage, buyPackageRequest>({
      query: (body) => ({
        url: 'payments/package',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['packages'],
    }),
  }),
});

export const { useBuyPackageMutation } = paymentsGenerate;
