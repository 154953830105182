import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid, Text } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';

import { PaymentMethods, useBuyPackageMutation } from '@api/payments.api';
import { useGetPromotionalPackagesCompanyQuery } from '@api/promotionalPackages.api';

import CardPromotionalPackages from '@components/cardPromotionalPackages/CardPromotionalPackages';
import Layout from '@components/layout/Layout';
import ModalPaymentStripe from '@components/ModalPaymentStripe';

export default function PromotionalPackages() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  const {
    data: promotionalPackages = [],
    isLoading: isLoadingPromotionalPackages,
  } = useGetPromotionalPackagesCompanyQuery();

  const [buyPackage, { isLoading: isLoadingBuyPackage }] =
    useBuyPackageMutation();

  useEffect(() => {
    if (isLoadingPromotionalPackages && !isLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPromotionalPackages]);
  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleBuyPackageSubmit = async (
    name: string,
    paymentMethod: PaymentMethods,
  ) => {
    try {
      const packages = await buyPackage({
        name: name,
        paymentMethods: paymentMethod,
      }).unwrap();

      if (paymentMethod === 'stripe') {
        openModal({
          title: <Text fw={700}>Pagamento ordine</Text>,
          children: (
            <ModalPaymentStripe
              price={packages.package.totalPrice}
              paymentClientSecret={packages.stripeClientSecret!}
              onSuccess={() => {
                setTimeout(() => {
                  navigate('/');
                }, 500);
              }}
            />
          ),
          size: '40%',
        });
      } else {
        closeAllModals();
        showNotification({
          title: 'Ordine confermato',
          message: 'Il pacchetto promozionale è stato ordinato',
        });
      }
    } catch (error) {
      showNotification({
        title: 'Impossibile proseguire',
        message: 'Errore inatteso, impossibile calcolare il prezzo',
        color: 'red',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Layout
      title="Pacchetti promozionali 2024"
      backLink={{ title: 'Torna alla lista pacchetti', to: '/' }}
    >
      <Grid>
        {promotionalPackages?.map((promo, index) => (
          <Grid.Col span={{ base: 12, md: 4, lg: 4 }} key={index}>
            <CardPromotionalPackages
              schema={promo}
              mostPopular={index === 1}
              onClick={(paymentMethod) =>
                handleBuyPackageSubmit(promo.name, paymentMethod)
              }
              loadingAction={isLoadingBuyPackage}
            />
          </Grid.Col>
        ))}
      </Grid>
    </Layout>
  );
}
