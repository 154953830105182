import { UseFormReturnType } from '@mantine/form';

import { isApiError } from '@api/index';

export const handleSubmitError = <T>(
  e: unknown,
  form: UseFormReturnType<T>,
) => {
  if (isApiError(e)) {
    if (e.status === 400) {
      form.setErrors({ general: e.data.message, ...e.data.errors });
    } else if (e.status === 401) {
      form.setErrors({ general: e.data.message });
    } else {
      form.setErrors({
        general: 'Unexpected error. Please try again.',
      });
    }
  } else {
    form.setErrors({
      general: 'Unexpected error. Please try again.',
    });
  }
};

export const requiredValidator = (val: string) =>
  val.trim() !== '' ? null : 'Campo obbligatorio';

export const requiredCompanyName = (val: string) => {
  switch (val) {
    case 'LaRegina42k':
      return true;
    case 'La32K M6C':
      return true;
    case 'StaffettaMiTi':
      return true;
    case 'Strabacino competitiva':
      return true;
  }
  return false;
};
