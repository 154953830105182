import { useDispatch } from 'react-redux';

import logo from '@images/logo_M6C.svg';
import { Group, Menu, Text, UnstyledButton } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconGift,
  IconGiftCard,
  IconGiftCardFilled,
  IconGiftFilled,
  IconLogout,
  IconSettings,
  IconUser,
  IconUserFilled,
} from '@tabler/icons-react';

import { useLogoutMutation } from '@api/auth.api';

import { logout } from '@slices/auth.slice';

import useAuth from '@hooks/useAuth';

import Icon from '@components/Icon';
import ModalUpdateProfile from '@components/ModalUpdateProfile';
import { SidebarItem } from '@components/sidebarItem/SidebarItem';

import classes from './Header.module.css';

export default function Header() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user } = useAuth();
  const dispatch = useDispatch();

  // ==========================================================================
  // Api
  // ==========================================================================
  const [logoutApiFun] = useLogoutMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleLogout = async () => {
    try {
      await logoutApiFun().unwrap();
      dispatch(logout());
    } catch (e) {
      showNotification({
        title: 'Error',
        message: 'Unexpected error. Please try again later',
      });
    }
  };

  const handleOpenModalClick = () => {
    openModal({
      title: <Text>Modifica profilo</Text>,
      children: <ModalUpdateProfile />,
      size: '40%',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <header className={classes.shell}>
      <Group justify="space-between" style={{ height: '100%' }} px="4rem">
        <img src={logo} className={classes.logo} alt="Logo" />
        {user?.role === 'admin' && (
          <div className={classes.menuItem}>
            <SidebarItem
              icon={IconUser}
              link="/"
              label="M6C"
              selectedIcon={IconUserFilled}
            />
            <SidebarItem
              icon={IconUser}
              link="/strabacino"
              label="Strabacino"
              selectedIcon={IconUserFilled}
            />
            <SidebarItem
              icon={IconGiftCard}
              link="/coupon"
              label="Coupon"
              selectedIcon={IconGiftCardFilled}
            />
            <SidebarItem
              icon={IconGift}
              link="/promotional-packages-bacs"
              label="Pacchetti"
              selectedIcon={IconGiftFilled}
            />
          </div>
        )}

        {user && (
          <nav>
            <Group>
              <Menu width={200}>
                <Menu.Target>
                  <UnstyledButton style={{ display: 'block' }}>
                    <Group>
                      <Icon
                        icon={IconSettings}
                        strokeWidth={1.5}
                        color="#ffffff"
                        fill="#01336C"
                        size="20"
                      />

                      <div style={{ flex: 1 }}>
                        <Text size="xs" fw={200} c="white">
                          {user && user.role.includes('company')
                            ? 'Società'
                            : 'Utente'}
                        </Text>
                        <Text size="sm" fw={500} c="white">
                          {user?.surname
                            ? `${user.name} ${user.surname}`
                            : user.name}
                        </Text>
                      </div>
                    </Group>
                  </UnstyledButton>
                </Menu.Target>
                <Menu.Dropdown>
                  {user && user.role.includes('company') && (
                    <Menu.Item
                      rightSection={<Icon icon={IconUser} size="14px" />}
                      onClick={handleOpenModalClick}
                    >
                      Modifica profilo
                    </Menu.Item>
                  )}

                  <Menu.Item
                    rightSection={<Icon icon={IconLogout} size="14px" />}
                    onClick={handleLogout}
                  >
                    Logout
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </Group>
          </nav>
        )}
      </Group>
    </header>
  );
}
