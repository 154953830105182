import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Tooltip } from '@mantine/core';
import { IconGift, IconInfoCircle } from '@tabler/icons-react';

import {
  PromotionalPackage,
  useGetPromotionalPackagesByUserQuery,
} from '@api/promotionalPackages.api';
import {
  RegistrationsApiQueryParams,
  SortBy,
  SortOrder,
} from '@api/registrations.api';

import Icon from '@components/Icon';
import Layout from '@components/layout/Layout';
import SearchAddContainer from '@components/SearchAddContainer';
import SortableTable from '@components/sortableTable/SortableTable';

import { dateToDateHourseString } from '../utils/date';

export default function DashboardCompany() {
  // ==========================================================================
  // State
  // ==========================================================================

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [filters, setFilters] = useState<RegistrationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get all promotional packages buyed
  const {
    data: dataPromotionalPackages = [],
    isLoading: isLoadingPromotionalPackagesByUser,
  } = useGetPromotionalPackagesByUserQuery();

  const promotionalPackages = dataPromotionalPackages.map(
    (pp: PromotionalPackage) => {
      const data = [
        pp.id,
        pp.name,
        dateToDateHourseString(pp.updatedAt.toString()),
        pp.paymentStatus === 'inProgress' &&
        pp.payments.paymentMethods === 'bacs' ? (
          <>
            <Tooltip label={'Pacchetto non ancora confermato'}>
              <Button
                size="xs"
                leftSection={<Icon icon={IconInfoCircle} />}
                disabled={true}
              >
                Lista iscritti
              </Button>
            </Tooltip>
          </>
        ) : (
          <Button
            size="xs"
            leftSection={<Icon icon={IconInfoCircle} />}
            to={`/${pp.id}`}
            component={Link}
          >
            Lista iscritti
          </Button>
        ),
      ];

      return {
        key: pp.paymentId,
        data,
      };
    },
  );

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  //TODO sistemare questo
  // const totalPages = Math.ceil(registrationsCount.count / filters.pageLength!);

  return (
    <Layout
      title="Pacchetti acquistati"
      actionButtons={[
        {
          label: 'Acquista pacchetti',
          onClick: () => {
            navigate('/promotional-packages');
          },
          icon: IconGift,
        },
      ]}
    >
      <SearchAddContainer
        searchPlaceholder="Ricerca per nome"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <SortableTable
          data={promotionalPackages}
          headings={{
            id: 'id',
            name: 'Nome',
            updatedDate: 'Data acquisto',
            actions: '',
          }}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as SortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun pacchetto acquistato"
          loading={isLoadingPromotionalPackagesByUser}
          lastColumnRight
        />
        {/* {registrations.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )} */}
      </SearchAddContainer>
    </Layout>
  );
}
