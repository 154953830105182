import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  MultiSelect,
  NumberInput,
  SegmentedControl,
  Select,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconInfoCircle, IconRefresh } from '@tabler/icons-react';

import {
  Coupon,
  CouponPriceType,
  TargetGender,
  useGenerateCodeOperatorMutation,
} from '@api/coupons.api';
import { isApiError } from '@api/index';
import { useGetMarathonsQuery } from '@api/marathons.api';

import { generateRandomAlphabeticString } from '../utils/coupon';
import { handleSubmitError } from '../utils/forms';
import Icon from './Icon';

interface CreateUpdateCouponModalProps {
  coupon?: Coupon;
}

export default function CreateUpdateCouponModal({
  coupon,
}: CreateUpdateCouponModalProps) {
  // ==========================================================================
  // Api
  // ==========================================================================
  const [createCoupon, { isLoading: isLoadingCreate }] =
    useGenerateCodeOperatorMutation();

  // Get marathons
  const { data: marathons = [], isLoading: isLoadingMarathons } =
    useGetMarathonsQuery({ category: 'M6C' });

  // ==========================================================================
  // Form
  // ==========================================================================
  const initialValues = {
    code: '',
    startDate: new Date(),
    endDate: null as Date | null,
    couponPriceType: 'absolute' as CouponPriceType,
    quantity: null as number | null,
    targetGender: 'all' as TargetGender,
    marathons: [] as Array<string>,
    marathonPrices: [] as Array<number>,
  };

  const form = useForm({
    initialValues,
    validate: {
      code: (value) => (value === '' ? 'Campo obbligatorio' : null),
      marathons: (value) => (value.length === 0 ? 'Campo obbligatorio' : null),
    },
  });

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const onSubmit = async (values: typeof initialValues) => {
    try {
      console.log(values);
      // Create
      const { startDate, endDate, ...valuesMap } = values;

      await createCoupon({
        startDate: new Date(startDate),
        endDate: endDate ? new Date(endDate) : endDate,
        ...valuesMap,
      }).unwrap();

      showNotification({
        title: 'Coupon creato',
        message: 'Coupon creato con successo',
      });

      closeAllModals();
    } catch (error) {
      if (isApiError(error) && error.status === 409) {
        form.setFieldError('code', 'Codice sconto già utilizzato');
        form.setFieldError('general', 'Uno o più campi contengono errori');
      } else {
        handleSubmitError(error, form);
      }
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <TextInput
        label="Codice"
        placeholder="Codice sconto"
        {...form.getInputProps('code')}
        maxLength={10}
        onChange={(e) =>
          form.setFieldValue('code', e.currentTarget.value.toUpperCase())
        }
        rightSection={
          !coupon && (
            <Tooltip label="Genera codice casuale">
              <ActionIcon
                variant="subtle"
                onClick={() =>
                  form.setFieldValue('code', generateRandomAlphabeticString(5))
                }
              >
                <IconRefresh />
              </ActionIcon>
            </Tooltip>
          )
        }
        disabled={coupon !== undefined}
      />
      <DateTimePicker
        mt="xs"
        label="Data inizio validità"
        minDate={new Date()}
        {...form.getInputProps('startDate')}
      />
      <DateTimePicker
        mt="xs"
        label="Data fine validità"
        clearable
        minDate={form.values.startDate}
        {...form.getInputProps('endDate')}
      />
      <Text fz="sm" fw="500" mt="sm">
        Settagio prezzo
      </Text>
      <Group>
        <SegmentedControl
          data={[
            { label: 'Assoluto', value: 'absolute' },
            { label: 'Relativo', value: 'relative' },
          ]}
          value={form.values.couponPriceType}
          onChange={(value) => {
            form.setFieldValue('couponPriceType', value as CouponPriceType);
          }}
        />
        <Tooltip label="ASSOLUTO: prezzo finale;  RELATIVO: sconto applicato al prezzo totale">
          <Icon icon={IconInfoCircle} />
        </Tooltip>
      </Group>

      <Group mt="xs" align="end">
        <NumberInput
          label="Utilizzi massimi consentiti"
          min={1}
          clampBehavior="strict"
          allowDecimal={false}
          disabled={form.values.quantity === null}
          {...form.getInputProps('quantity')}
        />
        <Checkbox
          label="Utilizzi illimitati"
          checked={form.values.quantity === null}
          onChange={(e) => {
            form.setFieldValue('quantity', e.currentTarget.checked ? null : 1);
          }}
        />
      </Group>
      <Select
        mt="xs"
        allowDeselect={false}
        key="category filter"
        label="Genere"
        description="Il coupon sarà valido solo per il genere selezionato"
        data={[
          { label: 'Entrambi', value: 'all' },
          { label: 'Maschio', value: 'male' },
          { label: 'Femmina', value: 'female' },
        ]}
        {...form.getInputProps('targetGender')}
      />
      <MultiSelect
        mt="xs"
        label="Maratone"
        description="Il coupon sarà valido solo per le maratone selezionate"
        data={marathons.map((m) => ({
          label: m.name,
          value: m.name,
        }))}
        disabled={isLoadingMarathons}
        // {...form.getInputProps('marathons')}
        onChange={(selectedMarathons: string[]) => {
          // Calcola le maratone rimosse
          const removedMarathons = form.values.marathons.filter(
            (marathon) => !selectedMarathons.includes(marathon),
          );

          // Rimuovi i prezzi corrispondenti alle maratone rimosse
          const updatedPrices = form.values.marathonPrices.filter(
            (_, index) =>
              !removedMarathons.includes(form.values.marathons[index]),
          );

          // Aggiorna i valori del form
          form.setFieldValue('marathons', selectedMarathons);
          form.setFieldValue('marathonPrices', updatedPrices);
        }}
      />

      {form.values.marathons.map((marathon, index) => (
        <NumberInput
          key={index}
          mt="xs"
          label={'Prezzo per ' + marathon}
          prefix="€ "
          min={0}
          clampBehavior="strict"
          value={form.values.marathonPrices[index]}
          onChange={(value) => {
            const price = parseFloat(value.toString());
            const updatedPrices = [...form.values.marathonPrices];
            updatedPrices[index] = price;
            form.setFieldValue('marathonPrices', updatedPrices);
          }}
        />
      ))}

      {form.errors.general && (
        <Text c="red" size="sm" mt="xl">
          {form.errors.general}
        </Text>
      )}

      <Group mt="xl" justify="end">
        <Button type="submit" loading={isLoadingCreate}>
          Conferma
        </Button>
      </Group>
    </form>
  );
}
