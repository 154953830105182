import {
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  Space,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { closeAllModals } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconAddressBook, IconUser } from '@tabler/icons-react';

import { isApiError } from '@api/index';

import useAuth from '@hooks/useAuth';

import statesData from '../data/nation.json';
import Icon from './Icon';

export default function ModalUpdateProfile() {
  // ==========================================================================
  // General
  // ==========================================================================
  const { user, patch } = useAuth();

  // ==========================================================================
  // Form
  // ==========================================================================

  const initialValues = {
    name: user?.name || '',
    vatNumber: user?.vatNumber || '',
    address: user?.address || '',
    city: user?.city || '',
    provincie: user?.provincie || '',
    cap: user?.cap || '',
    sdiCode: user?.sdiCode || '',
    state: user?.state || '',
    email: user?.email || '',
    phone: user?.phone || '',
  };

  const form = useForm({
    initialValues,
    validate: {
      name: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      vatNumber: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      address: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      city: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      provincie: (val) =>
        /^[a-zA-Z]+$/.test(val) ? null : 'Può contenere solo lettere',
      sdiCode: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      state: (val) => (val !== '' ? null : 'Campo obbligatorio'),
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email invalida'),
      phone: (val) => (val !== '' ? null : 'Campo obbligatorio'),
    },
  });

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await patch(values);

      showNotification({
        title: 'Profilo modificato',
        message: 'Il profilo è stato modificato con successo',
      });

      closeAllModals();
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else {
          form.setErrors({
            general: 'Errore inatteso. Per favore riprova più tardi.',
          });
        }
      } else {
        form.setErrors({
          general: 'Errore inatteso. Per favore riprova più tardi.',
        });
      }
    }
  };

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const selectNationItems = statesData.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onSubmit(values);
      })}
    >
      <Grid>
        {/* ================================== */}
        <Grid.Col span={12}>
          <Group>
            <Icon icon={IconUser} />
            <Text fz="16px" fw={600}>
              Informazioni di base
            </Text>
          </Group>
        </Grid.Col>
        {/* ROW - name */}
        <Grid.Col span={6}>
          <TextInput required label="Nome:" {...form.getInputProps('name')} />
        </Grid.Col>
        {/* ROW - vatNumber */}
        <Grid.Col span={6}>
          <TextInput
            required
            label="P.Iva o CF:"
            {...form.getInputProps('vatNumber')}
          />
        </Grid.Col>
        {/* ROW - email */}
        <Grid.Col span={4}>
          <TextInput required label="Email:" {...form.getInputProps('email')} />
        </Grid.Col>
        {/* ROW - phonenumber */}
        <Grid.Col span={4}>
          <TextInput
            required
            label="Telefono:"
            {...form.getInputProps('phone')}
          />
        </Grid.Col>
        {/* ROW - vatNumber */}
        <Grid.Col span={4}>
          <TextInput
            required
            label="Codice SDI:"
            {...form.getInputProps('sdiCode')}
            minLength={7}
            maxLength={7}
          />
        </Grid.Col>
        <Space h="lg" />
        {/* ================================== */}
        <Grid.Col span={12}>
          <Divider />
          <Space h="md" />
          <Group>
            <Icon icon={IconAddressBook} />
            <Text fz="16px" fw={600}>
              Indirizzo
            </Text>
          </Group>
        </Grid.Col>
        {/* ROW - state */}
        <Grid.Col span={6}>
          <Select
            required
            label="Stato:"
            searchable
            {...form.getInputProps('state')}
            data={selectNationItems}
          />
        </Grid.Col>
        {/* ROW - province */}
        <Grid.Col span={6}>
          <TextInput
            required
            label="Provincia (SIGLA):"
            {...form.getInputProps('provincie')}
            maxLength={2}
          />
        </Grid.Col>
        {/* ROW - city */}
        <Grid.Col span={4}>
          <TextInput required label="Città:" {...form.getInputProps('city')} />
        </Grid.Col>
        {/* ROW - address */}
        <Grid.Col span={4}>
          <TextInput
            required
            label="Indirizzo:"
            {...form.getInputProps('address')}
          />
        </Grid.Col>
        {/* ROW - cap */}
        <Grid.Col span={4}>
          <TextInput label="Cap:" {...form.getInputProps('cap')} />
        </Grid.Col>
        <Grid.Col span={12}>
          <Flex justify="flex-end" align="center" direction="row">
            <Button type="submit">Conferma</Button>
          </Flex>
        </Grid.Col>
      </Grid>
    </form>
  );
}
