import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import { Group, rem, Text, useMantineTheme } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';

import Icon from '@components/Icon';

import classes from './SidebarItem.module.css';

interface SidebarItemProps {
  link: string;
  label: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  selectedIcon: (props: TablerIconsProps) => JSX.Element;
  rightSection?: ReactNode;
}

export function SidebarItem({
  link,
  label,
  icon,
  selectedIcon,
  rightSection,
}: SidebarItemProps) {
  // ==========================================================================
  // General
  // ==========================================================================
  const theme = useMantineTheme();

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <NavLink to={link} key={link} end className={classes.link}>
      <Icon
        icon={icon}
        className="icon"
        size="1.9rem"
        style={{
          marginRight: theme.spacing?.md,
        }}
      />
      <Icon
        icon={selectedIcon}
        className="selected-icon"
        size="1.9rem"
        style={{
          marginRight: theme.spacing?.md,
        }}
      />
      <Group justify="space-between" style={{ width: '100%' }}>
        <Text fz={rem('1.12rem')} className={classes.label}>
          {label}
        </Text>
        {rightSection}
      </Group>
    </NavLink>
  );
}
