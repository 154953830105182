import { Divider, Grid, Group, Space, Text } from '@mantine/core';
import { IconAddressBook, IconFiles, IconUser } from '@tabler/icons-react';

import { FormTypeStrabacino } from '@interfaces/form.interface';

import { dateToNormaFormat } from '../utils/date';
import { requiredCompanyName } from '../utils/forms';
import { labelGender } from '../utils/mapLabel';
import Icon from './Icon';

interface DataSummaryProps {
  form: FormTypeStrabacino;
  price?: number;
  marathon: string;
}

export default function DataSummaryStrabacino({
  form,
  marathon,
}: DataSummaryProps) {
  return (
    <Grid>
      {/* ================================== */}
      <Grid.Col span={12}>
        <Group>
          <Icon icon={IconUser} />
          <Text fz="16px" fw={600}>
            Informazioni di base
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - name */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Nome:
        </Text>
        <Text fz={'14px'}>{form.values.name || '-'}</Text>
      </Grid.Col>
      {/* ROW - surname */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Cognome:
        </Text>
        <Text fz={'14px'}>{form.values.surname || '-'}</Text>
      </Grid.Col>
      {/* ROW - birthdate */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Data di nascita:
        </Text>
        <Text fz={'14px'}>
          {dateToNormaFormat(form.values.birthDate!.toString()) || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - gender */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Genere:
        </Text>
        <Text fz={'14px'}>{labelGender(form.values.gender)}</Text>
      </Grid.Col>
      {/* ROW - email */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Email:
        </Text>
        <Text fz={'14px'}>{form.values.email || '-'}</Text>
      </Grid.Col>
      {/* ROW - phone */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Telefono:
        </Text>
        <Text fz={'14px'}>{form.values.phone || '-'}</Text>
      </Grid.Col>
      {requiredCompanyName(marathon) && (
        <>
          {/* ROW - companyName */}
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Text fz={'15px'} fw={500}>
              Nome società:
            </Text>
            <Text fz={'14px'}>{form.values.companyName || '-'}</Text>
          </Grid.Col>
        </>
      )}

      {/* ================================== */}
      <Grid.Col span={12}>
        <Divider />
        <Space h="md" />
        <Group>
          <Icon icon={IconAddressBook} />
          <Text fz="16px" fw={600}>
            Indirizzo
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - state */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Stato:
        </Text>
        <Text fz={'14px'}>{form.values.state || '-'}</Text>
      </Grid.Col>
      {/* ROW - city */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Città:
        </Text>
        <Text fz={'14px'}>{form.values.city || '-'}</Text>
      </Grid.Col>
      {/* ROW - provincie */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Provincia:
        </Text>
        <Text fz={'14px'}>{form.values.provincie || '-'}</Text>
      </Grid.Col>
      {/* ROW - address */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Indirizzo:
        </Text>
        <Text fz={'14px'}>{form.values.address || '-'}</Text>
      </Grid.Col>
      {/* ROW - nationality */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Nazionalità:
        </Text>
        <Text fz={'14px'}>{form.values.nationality || '-'}</Text>
      </Grid.Col>
      {/* ROW - cap */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          CAP:
        </Text>
        <Text fz={'14px'}>{form.values.cap || '-'}</Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <Divider />
        <Space h="md" />
        <Group>
          <Icon icon={IconFiles} />
          <Text fz="16px" fw={600}>
            Altre informazioni
          </Text>
        </Group>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Scadenza certificato:
        </Text>
        <Text fz={'14px'}>
          {form.values.certificateDateExpiry !== null
            ? dateToNormaFormat(
                form.values.certificateDateExpiry?.toString() ?? '',
              )
            : '-'}
        </Text>
      </Grid.Col>

      <Grid.Col span={12} hiddenFrom="sm">
        <Text fz="15px" fw={500}>
          Maratona:
        </Text>
        <Text fz="14px">{marathon}</Text>
      </Grid.Col>
    </Grid>
  );
}
