import { Container, Divider, Grid, Group, Space, Text } from '@mantine/core';
import { IconAddressBook, IconFiles, IconUser } from '@tabler/icons-react';

import { FormTypeM6C } from '@interfaces/form.interface';

import { dateToNormaFormat } from '../utils/date';
import { requiredCompanyName } from '../utils/forms';
import { labelGender } from '../utils/mapLabel';
import Icon from './Icon';

interface DataSummaryProps {
  indexPartecipant: number;
  form: FormTypeM6C;
  marathon: string;
  coupon: boolean | undefined;
}

export default function DataSummaryM6C({
  indexPartecipant,
  form,
  marathon,
  coupon,
}: DataSummaryProps) {
  const checkMarathonFidal = (val: string) => {
    switch (val) {
      case 'La10M6C':
        return true;
      case 'MezzaMaratona21km':
        return true;
    }
    return false;
  };

  return (
    <Grid>
      {/* ================================== */}
      {marathon === 'StaffettaMiTi' && (
        <Grid.Col span="content">
          <Container
            c="#ff0000"
            p="0.5rem"
            fw={600}
            style={{ borderRadius: '8px' }}
          >
            {indexPartecipant + 1} Partecipante
            {form.values.participants[indexPartecipant].raceLength === 'l_10km'
              ? ' (10km)'
              : ' (32km)'}
          </Container>
        </Grid.Col>
      )}
      <Grid.Col span={12}>
        <Group>
          <Icon icon={IconUser} />
          <Text fz="16px" fw={600}>
            Informazioni di base
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - name */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Nome:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].name || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - surname */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Cognome:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].surname || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - birthdate */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Data di nascita:
        </Text>
        <Text fz={'14px'}>
          {dateToNormaFormat(
            form.values.participants[indexPartecipant]?.birthDate!.toString(),
          ) || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - gender */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Genere:
        </Text>
        <Text fz={'14px'}>
          {labelGender(form.values.participants[indexPartecipant].gender)}
        </Text>
      </Grid.Col>
      {/* ROW - email */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Email:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].email || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - phone */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Telefono:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].phone || '-'}
        </Text>
      </Grid.Col>
      {requiredCompanyName(marathon) && (
        <>
          {/* ROW - companyName */}
          <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
            <Text fz={'15px'} fw={500}>
              Nome società:
            </Text>
            <Text fz={'14px'}>
              {form.values.participants[indexPartecipant].companyName || '-'}
            </Text>
          </Grid.Col>
        </>
      )}

      {/* ================================== */}
      <Grid.Col span={12}>
        <Divider />
        <Space h="md" />
        <Group>
          <Icon icon={IconAddressBook} />
          <Text fz="16px" fw={600}>
            Indirizzo
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - state */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Stato:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].state || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - city */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Città:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].city || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - provincie */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Provincia:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].provincie || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - address */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Indirizzo:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].address || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - nationality */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Nazionalità:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].nationality || '-'}
        </Text>
      </Grid.Col>
      {/* ROW - cap */}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          CAP:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant].cap || '-'}
        </Text>
      </Grid.Col>
      <Grid.Col span={12}>
        <Divider />
        <Space h="md" />
        <Group>
          <Icon icon={IconFiles} />
          <Text fz="16px" fw={600}>
            Altre informazioni
          </Text>
        </Group>
      </Grid.Col>
      {/* ROW - date expiry */}
      {checkMarathonFidal(marathon) && (
        <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
          <Text fz={'15px'} fw={500}>
            Numero carta:
          </Text>
          <Text fz={'14px'}>
            {form.values.participants[indexPartecipant]?.cardNumber || '-'}
          </Text>
        </Grid.Col>
      )}
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz={'15px'} fw={500}>
          Scadenza certificato:
        </Text>
        <Text fz={'14px'}>
          {form.values.participants[indexPartecipant]?.certificateDateExpiry !==
          null
            ? dateToNormaFormat(
                form.values.participants[
                  indexPartecipant
                ]?.certificateDateExpiry?.toString() ?? '',
              )
            : '-'}
        </Text>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
        <Text fz="15px" fw={500}>
          Coupon:
        </Text>

        <Text fz="14px">
          {form.values.coupon === ''
            ? '-'
            : coupon
              ? `Valido (${form.values.coupon})`
              : 'Non valido'}
        </Text>
      </Grid.Col>

      <Grid.Col span={12} hiddenFrom="sm">
        <Text fz="15px" fw={500}>
          Maratona:
        </Text>
        <Text fz="14px">{marathon}</Text>
      </Grid.Col>

      {marathon === 'StaffettaMiTi' && (
        <Grid.Col span={12}>
          <Space h="xl" />
        </Grid.Col>
      )}
    </Grid>
  );
}
