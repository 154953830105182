import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import logo from '@images/logo_strabacino.svg';
import {
  Anchor,
  Button,
  Checkbox,
  Container,
  Divider,
  Group,
  Select,
  Space,
  Stepper,
  Table,
  Text,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

import env from '@config/env';

import {
  PriceByCouponResponse,
  useLazyCheckCouponQuery,
} from '@api/couponMarathon.api';
import { isApiError } from '@api/index';
import { useGetMarathonsQuery } from '@api/marathons.api';
import { MarathonType, SubscribersGender } from '@api/registrations.api';
import {
  SubscriberDataDto,
  SubscribersCertificateExpiry,
  useRegisterSubscriberMutation,
  useUploadImagesMutation,
} from '@api/subscribers.api';

import useScript from '@hooks/useScript';

import DataSummaryStrabacino from '@components/DataSummaryStrabacino';
import Icon from '@components/Icon';
import ModalPaymentStripe from '@components/ModalPaymentStripe';
import RegisterFormStrabacino from '@components/RegisterFormStrabacino';

import { requiredValidator } from '../../utils/forms';
import { formatMarathonOption, labelMarathon } from '../../utils/mapLabel';
import classes from './RegistrationsStrabacino.module.css';

export default function RegistrationsStrabacino() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  useScript(
    'https://www.google.com/recaptcha/api.js?render=6LezXVcnAAAAAH8fH9hAa-V_K5JFD3tdVJyTz7Lc',
  );

  // ==========================================================================
  // State
  // ==========================================================================
  const [activeStep, setActiveStep] = useState(0);
  const [openRegistration] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [priceByCoupon, setPriceByCoupon] = useState<PriceByCouponResponse>();

  const [marathonChosen, setMarathonChosen] = useState<string | null>(
    searchParams.get('percorso') || null,
  );
  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);
  // ==========================================================================
  // Api
  // ==========================================================================

  // Register subscriber and create order Stripe
  const [registerSubscriber, { isLoading: isLoadingSubscriber }] =
    useRegisterSubscriberMutation();

  // Get Marathons
  const { data: marathons = [], isLoading: isLoadingMarathons } =
    useGetMarathonsQuery({ category: 'Strabacino' });

  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadImagesMutation();

  const [checkCoupon, { isLoading: isLoadingCheckCcoupon }] =
    useLazyCheckCouponQuery();

  // ==========================================================================
  // Form
  // ==========================================================================

  const initialValues = {
    name: '',
    surname: '',
    birthDate: null as Date | null,
    gender: 'male',
    nationality: 'Italiana',
    address: '',
    city: '',
    provincie: '',
    cap: '',
    state: 'Italia',
    email: '',
    phone: '',
    condition: false,
    companyName: '',
    certificateDateExpiry: null as Date | null,
    certificateExpiry: null as SubscribersCertificateExpiry | null,
    certificateFile: null as File | null,
    privacy: false,
  };

  const form = useForm({
    initialValues,
    validate: {
      name: (val) => requiredValidator(val),
      surname: (val) => requiredValidator(val),
      gender: (val) =>
        val === 'male' || val === 'female' ? null : 'Campo obbligatorio',
      nationality: (val) => requiredValidator(val),
      address: (val) => requiredValidator(val),
      city: (val) => requiredValidator(val),
      birthDate: (val) => {
        if (!val) return 'Campo obbligatorio';

        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

        if (val > eighteenYearsAgo) {
          return 'Devi avere almeno 18 anni';
        }

        return null;
      },
      provincie: (val) =>
        /^[a-zA-Z]+$/.test(val) ? null : 'Può contenere solo lettere',
      state: (val) => requiredValidator(val),
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email invalida'),
      phone: (val) => requiredValidator(val),
      condition: (val) => {
        return !val ? 'Campo obbligatorio' : null;
      },
      certificateFile: (val) => {
        const fileType = ['image/jpeg', 'image/jpg', 'application/pdf'];

        if (val) {
          if (val.size > 4000000) {
            return 'La massima dimensione consentita è 4MB';
          } else if (!fileType.includes(val.type)) {
            return 'Il tipo di file non è supportato. Sono accettati solo file JPG o PDF';
          }
        }

        return null;
      },
      privacy: (val) =>
        val === false ? 'Impossibile proseguire. Accettare la privacy' : null,
    },
  });

  const subscriberRegister = async (
    values: typeof initialValues,
    token?: string,
  ) => {
    try {
      setIsLoadingOnSubmit(true);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { condition, ...rest } = values;

      const response = await registerSubscriber({
        subscriberDataDto: [rest] as SubscriberDataDto[],
        marathon: marathonChosen!,
        recaptchaToken: token!,
      }).unwrap();

      if (response) {
        const formData = new FormData();

        response.subscriberDataWithPayment.forEach(async (p) => {
          formData.append('participants_id', p.id.toString());
          formData.append('participants_name', p.name + p.surname);
          if (rest.certificateFile) {
            formData.append('participants_certificate', rest.certificateFile);
          }
        });

        await uploadImage(formData).unwrap();

        const paymentStatus = response.paymentStatus;
        const clientSecret = response.stripeClientSecret;
        if (paymentStatus === 'inProgress' && clientSecret) {
          handleOpenModalStripeClick(clientSecret);
        } else if (
          paymentStatus === 'completed' &&
          priceByCoupon?.price === 0
        ) {
          showNotification({
            title: 'Iscrizione riuscita',
            message: 'Riceverai una mail di conferma',
            icon: <Icon icon={IconCheck} />,
            color: 'teal',
          });
          window.location.href = `https://m6c.it/thankyoupage/`;
        }
      }
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
          prevStep();
        } else {
          form.setErrors({
            general: 'Errore imprevisto, controlla i campi e riprova',
          });
        }
      }
    } finally {
      setIsLoadingOnSubmit(false);
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    if (env.MODE === 'production') {
      // @ts-ignore
      window['grecaptcha'].ready(function () {
        // @ts-ignore
        window['grecaptcha']
          .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
          .then(async (token: string) => {
            subscriberRegister(values, token);
          });
      });
    } else {
      subscriberRegister(values);
    }
  };

  useEffect(() => {
    if (
      isLoadingSubscriber &&
      isLoadingMarathons &&
      isLoadingUploadImage &&
      isLoadingCheckCcoupon &&
      !isLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingSubscriber,
    isLoadingUploadImage,
    isLoadingMarathons,
    isLoadingCheckCcoupon,
  ]);

  useEffect(() => {
    form.values.companyName = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marathonChosen]);

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const nextStep = async () => {
    await form.validate();

    if (form.isValid()) {
      let responseChceckCoupon: PriceByCouponResponse =
        {} as PriceByCouponResponse;
      responseChceckCoupon = await checkCoupon({
        marathon: marathonChosen as MarathonType,
        code: '',
        targetGender: [form.values.gender as SubscribersGender],
      }).unwrap();

      setPriceByCoupon(responseChceckCoupon);

      if (
        marathonChosen !== '' &&
        marathons.some((marathon) => marathon.name === marathonChosen)
      ) {
        setActiveStep((current) => (current < 3 ? current + 1 : current));
      } else if (marathonChosen === '') {
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Nessun percorso selezionato, seleziona un percorso',
          color: 'red',
        });
      } else if (responseChceckCoupon.price === undefined) {
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Errore inatteso, impossibile calcolare il prezzo',
          color: 'red',
        });
      } else {
        form.setErrors({
          general: 'Errore imprevisto, tesseramento non valido',
        });
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Errore inatteso',
          color: 'red',
        });
      }
    }
  };

  const prevStep = () => {
    setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  const setMarathonParams = (val: string) => {
    setMarathonChosen(val!);
    searchParams.set('percorso', val!);
    setSearchParams(searchParams);
  };

  const handleOpenModalStripeClick = (value: string) => {
    openModal({
      title: <Text fw={700}>Pagamento ordine</Text>,
      children: (
        <ModalPaymentStripe
          price={priceByCoupon!.price}
          paymentClientSecret={value}
          onSuccess={() => {
            window.location.href = `https://m6c.it/thankyoupage/`;
          }}
        />
      ),
      closeOnClickOutside: false,
      withCloseButton: false,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <>
      <div className={classes.container}>
        <Container size="md" py={'4rem'}>
          <Group justify="space-between">
            <Button>
              <Link to="https://m6c.it/" className={classes.linkStyle}>
                Torna al sito
              </Link>
            </Button>

            <Title c="white" visibleFrom="sm">
              Iscrizione Strabacino 2024
            </Title>

            <img src={logo} alt="logo" style={{ width: '10rem' }} />
          </Group>
          <div className={classes.itemRegistration}>
            {openRegistration ? (
              <form
                onSubmit={form.onSubmit((values) => {
                  onSubmit(values);
                })}
              >
                <Title c="#01336C" order={3} hiddenFrom="sm">
                  Iscrizione Strabacino 2024
                </Title>
                <Stepper active={activeStep}>
                  <Stepper.Step
                    label="Inserimento dati"
                    description="Iscrizione"
                  >
                    <Group justify="left" align="end" mt="1.3rem">
                      <Select
                        label="Percorso:"
                        required
                        value={marathonChosen}
                        data={marathons.map(formatMarathonOption)}
                        onChange={(value) => setMarathonParams(value!)}
                        allowDeselect={false}
                        className={classes.changeWidth}
                      />
                    </Group>

                    <Space h="xl" />

                    <RegisterFormStrabacino
                      form={form}
                      price={priceByCoupon?.price}
                      marathon={marathonChosen!}
                    />
                  </Stepper.Step>
                  <Stepper.Step label="Riepilogo dati" description="Controllo">
                    <div>
                      <DataSummaryStrabacino
                        form={form}
                        marathon={marathonChosen!}
                      />
                    </div>
                    <div>
                      <Space h="md" />

                      <Table striped visibleFrom="sm">
                        <Table.Thead>
                          <Table.Tr>
                            <Table.Th>Percorso</Table.Th>
                            <Table.Th>Partecipante</Table.Th>
                            <Table.Th>Prezzo</Table.Th>
                          </Table.Tr>
                        </Table.Thead>
                        <Table.Tbody>
                          <Table.Tr>
                            <Table.Td>
                              {labelMarathon(marathonChosen!)}
                            </Table.Td>
                            <Table.Td>{form.values.name}</Table.Td>
                            <Table.Td>
                              {Number(
                                ((priceByCoupon?.price || 0) / 100).toFixed(2),
                              ).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR',
                              })}{' '}
                            </Table.Td>
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                      {marathonChosen === 'Strabacino competitiva' && (
                        <Group justify="right" visibleFrom="sm">
                          <Text fz={'0.65rem'}>
                            La quota comprende
                            <span style={{ fontWeight: 600 }}> pacco gara</span>
                            ,{' '}
                            <span style={{ fontWeight: 600 }}>
                              {' '}
                              assicurazione
                            </span>
                            ,{' '}
                            <span style={{ fontWeight: 600 }}>
                              {' '}
                              deposito borse
                            </span>
                            , <span style={{ fontWeight: 600 }}>
                              medaglia
                            </span>{' '}
                            e{' '}
                            <span style={{ fontWeight: 600 }}>
                              pasta party finale.
                            </span>
                          </Text>
                        </Group>
                      )}

                      <Group justify="right" mt="2rem">
                        <Text size="xl" fz={'0.9rem'}>
                          TOTALE
                        </Text>
                      </Group>
                      <Group justify="right">
                        <Text size="xl" fw={700} fz={'2rem'}>
                          {Number(
                            ((priceByCoupon?.price || 0) / 100).toFixed(2),
                          ).toLocaleString('it-IT', {
                            style: 'currency',
                            currency: 'EUR',
                          })}
                        </Text>
                      </Group>
                    </div>
                  </Stepper.Step>
                </Stepper>

                <Divider my="md" />
                <Group justify="space-between">
                  {activeStep !== 0 && (
                    <>
                      <Button variant="default" onClick={prevStep}>
                        Indietro
                      </Button>

                      <Button
                        type="submit"
                        loading={isLoadingSubscriber && isLoadingOnSubmit}
                      >
                        {priceByCoupon?.price === 0
                          ? 'Iscriviti'
                          : 'Iscriviti e paga'}
                      </Button>
                    </>
                  )}

                  {activeStep < 1 && (
                    <>
                      <Group>
                        <Checkbox
                          {...form.getInputProps('privacy', {
                            type: 'checkbox',
                          })}
                          label={
                            <div className={classes.privacyPolicy}>
                              <span>
                                Autorizzo il trattamento dei miei dati personali
                              </span>
                              &nbsp;
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    '<a href="https://www.iubenda.com/privacy-policy/89956701" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy "> Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
                                }}
                              ></div>
                            </div>
                          }
                        />
                      </Group>

                      <Button
                        onClick={nextStep}
                        className={classes.changeWidth}
                      >
                        Riepilogo
                      </Button>
                    </>
                  )}
                </Group>
                {form.isValid() && (
                  <Text c="red" size="md" mt="md">
                    {form.errors.general}
                  </Text>
                )}
              </form>
            ) : (
              <>
                Le iscrizioni alla maratona <em>Strabacino 2024</em> sono
                attualmente chiuse. Sarà possibile iscriversi sul posto dalle
                ore 17:00 fino a mezz'ora prima della gara.
                <Group justify="end" w="100%" visibleFrom="sm">
                  <Text c="dimmed" fz="sm">
                    Per info:
                  </Text>
                  <Anchor
                    href={'mailto:strabacino@gmail.com'}
                    fz="sm"
                    c="dimmed"
                  >
                    strabacino@gmail.com
                  </Anchor>
                  <Text c="dimmed">|</Text>
                  <Anchor href={'tel:0445856245'} fz="sm" c="dimmed">
                    +39 0445 856245
                  </Anchor>
                </Group>
                <Group w="100%" hiddenFrom="sm" mt="1rem">
                  <Text c="dimmed" fz="sm">
                    Per info:
                  </Text>
                  <Anchor
                    href={'mailto:strabacino@gmail.com'}
                    fz="sm"
                    c="dimmed"
                  >
                    strabacino@gmail.com
                  </Anchor>

                  <Anchor href={'tel:0445856245'} fz="sm" c="dimmed">
                    +39 0445 856245
                  </Anchor>
                </Group>
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
