import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Group, Menu, Text, Tooltip } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCircleCheck, IconTools, IconXboxX } from '@tabler/icons-react';

import {
  PromotionalPackageApiQueryParams,
  useConfirmPromotionalPackagesMutation,
  useGetCompanyPendingPackagesCountQuery,
  useGetCompanyPendingPackagesQuery,
  useRejectPromotionalPackagesMutation,
} from '@api/promotionalPackages.api';

import Icon from '@components/Icon';
import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';
import SortableTable from '@components/sortableTable/SortableTable';

import { dateToDateHourseString } from '../utils/date';
import { mapDataLabelPaymentStatus } from '../utils/promotionalPackages';

export default function PromotionalPackagesAdmin() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<PromotionalPackageApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
  });

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  const {
    data: promotionalPackages = [],
    isLoading: isLoadingCompanyPendingPackages,
  } = useGetCompanyPendingPackagesQuery({ ...filters });

  const {
    data: promotionalPackagesCount = { count: 0 },
    isLoading: isLoadingpromotionalPackagesCount,
  } = useGetCompanyPendingPackagesCountQuery();
  // Confirm PromotionalPackages
  const [confirmPromotionalPackages] = useConfirmPromotionalPackagesMutation();

  // Reject PromotionalPackages
  const [rejectPromotionalPackages] = useRejectPromotionalPackagesMutation();
  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const promotionalPackagesData = promotionalPackages.map((c) => {
    const data = [
      dateToDateHourseString(c.createdAt.toString()),
      c.users?.name,
      c.name,
      c.users.email,
      Number((c.payments.total! / 100).toFixed(2)).toLocaleString('it-IT', {
        style: 'currency',
        currency: 'EUR',
      }) ?? 'N.d.',
      mapDataLabelPaymentStatus(c.paymentStatus),

      <Group justify="right">
        {c.paymentStatus === 'inProgress' ? (
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <Button size="xs" leftSection={<Icon icon={IconTools} />}>
                Gestisci
              </Button>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Gestisci Pacchetti</Menu.Label>

              <Menu.Item
                leftSection={<Icon icon={IconCircleCheck} />}
                onClick={async () => {
                  openConfirmModal({
                    title: 'Conferma pacchetto promozionale',
                    children: (
                      <Text fz="sm">
                        Stai per confermare l'avvenuto ricezione del bonifico
                        bancario. Sicuro di voler procedere?
                      </Text>
                    ),
                    labels: {
                      confirm: 'Conferma ricezione',
                      cancel: 'Annulla',
                    },
                    confirmProps: { color: 'red' },
                    onConfirm: async () => {
                      try {
                        await confirmPromotionalPackages(c.id).unwrap();
                        showNotification({
                          color: 'green',
                          title: 'Successo',
                          message: 'Pacchetto confermato con successo',
                        });
                      } catch (e) {
                        showNotification({
                          color: 'red',
                          title: 'Errore',
                          message: 'Errore nella gestione del pacchetto',
                        });
                      }
                    },
                  });
                }}
              >
                Conferma
              </Menu.Item>
              <Menu.Item
                leftSection={<Icon icon={IconXboxX} />}
                onClick={async () => {
                  openConfirmModal({
                    title: 'Annulla acquisto',
                    children: (
                      <Text fz="sm">
                        Stai per annullare l'acquisto del pacchetto
                        promozionale. Sicuro di voler procedere?
                      </Text>
                    ),
                    labels: {
                      confirm: 'Annulla acquisto',
                      cancel: 'Annulla',
                    },
                    confirmProps: { color: 'red' },
                    onConfirm: async () => {
                      try {
                        await rejectPromotionalPackages(c.id).unwrap();
                        showNotification({
                          color: 'green',
                          title: 'Successo',
                          message:
                            'Acquiso del pacchetto annullato con successo',
                        });
                      } catch (e) {
                        showNotification({
                          color: 'red',
                          title: 'Errore',
                          message: 'Errore nella gestione del pacchetto',
                        });
                      }
                    },
                  });
                }}
              >
                Annulla
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        ) : (
          <Tooltip label="Pacchetto gestito">
            <Button
              size="xs"
              leftSection={<Icon icon={IconCircleCheck} />}
              disabled={true}
            >
              Gestisci
            </Button>
          </Tooltip>
        )}
      </Group>,
    ];
    return {
      key: c.id,
      data,
    };
  });

  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(
    promotionalPackagesCount.count / filters.pageLength!,
  );

  return (
    <Layout title="Pacchetti promozionali" actionButtons={[]}>
      <SearchAddContainer>
        <SortableTable
          data={promotionalPackagesData}
          headings={{
            createdAt: 'Data acquisto',
            name: 'Azienda',
            promotionalPackagesType: 'Tipo',
            email: 'Email',
            price: 'Prezzo',
            paymentStatus: 'Stato',
            actionsIcon: '',
          }}
          emptyText="Nessun pacchetto promozionale trovato"
          loading={
            isLoadingCompanyPendingPackages || isLoadingpromotionalPackagesCount
          }
          lastColumnRight
        />
        {promotionalPackagesData.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </Layout>
  );
}
