import { createBrowserRouter } from 'react-router-dom';

import DashboardAdminStrabacino from '@components/dashboardAdminStrabacino';
import PasswordReset from '@components/PasswordReset';
import PasswordResetRequest from '@components/PasswordResetRequest';
import PrivateRoute from '@components/PrivateRoute';
import RoleSpecificRoute from '@components/RoleSpecificRoute';
import Root from '@components/Root';

import ActivateUser from './ActivateUser';
import CouponPage from './couponPage/CouponPage';
import Dashboard from './Dashboard';
import ListSubscribersCompany from './listSubscribersCompany/ListSubscribersCompany';
import Login from './Login';
import NotFound from './notFound/NotFound';
import PromotionalPackages from './promotionalPackages/PromotionalPackages';
import PromotionalPackagesAdmin from './PromotionalPackagesAdmin';
import RegistrationsCompany from './registrationsCompany/RegistrationsCompany';
import RegistrationsM6C from './registrationsM6C/RegistrationsM6C';
import RegistrationsStrabacino from './registrationsStrabacino/RegistrationsStrabacino';

export const LOGIN_PATH = '/login';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <PrivateRoute element={<Dashboard />} />,
      },
      {
        path: ':id',
        element: (
          <RoleSpecificRoute
            element={<ListSubscribersCompany />}
            role={'company'}
          />
        ),
      },
      {
        path: 'promotional-packages',
        element: (
          <RoleSpecificRoute
            role={'company'}
            element={<PromotionalPackages />}
          />
        ),
      },
      { path: 'users/activate/:token', element: <ActivateUser /> },
    ],
  },
  { path: 'login', element: <Login /> },
  { path: 'register', element: <RegistrationsM6C /> },
  { path: 'register-strabacino', element: <RegistrationsStrabacino /> },
  {
    path: 'strabacino',
    element: (
      <RoleSpecificRoute
        role={'admin'}
        element={<DashboardAdminStrabacino />}
      />
    ),
  },
  {
    path: 'coupon',
    element: <RoleSpecificRoute role={'admin'} element={<CouponPage />} />,
  },
  {
    path: 'promotional-packages-bacs',
    element: (
      <RoleSpecificRoute
        role={'admin'}
        element={<PromotionalPackagesAdmin />}
      />
    ),
  },
  { path: 'register-company', element: <RegistrationsCompany /> },
  { path: 'password-reset', element: <PasswordResetRequest /> },
  { path: '/password-reset/:token', element: <PasswordReset /> },

  { path: '*', element: <NotFound /> },
]);

export default router;
