import { Coupon } from '@api/coupons.api';

import SortableTable from './sortableTable/SortableTable';

interface ModalListCouponProps {
  data: Coupon[];
}

export default function ModalListCoupon({ data }: ModalListCouponProps) {
  const coupons = data.map((c: Coupon) => {
    const data = [
      c.couponMarathons[0].marathonId,
      c.code,
      c.couponMarathons[0].quantity,
    ];
    return {
      key: c.code,
      data,
    };
  });
  return (
    <SortableTable
      data={coupons}
      headings={{
        marathon: 'Maratona',
        code: 'Coupon',
        quantity: 'Utilizzi disponibili',
      }}
      emptyText="Nessun coupon trovato"
    />
  );
}
