import { CSSProperties, forwardRef, ReactNode } from 'react';

import { rem } from '@mantine/core';
import { TablerIconsProps } from '@tabler/icons-react';

interface IconProps extends Omit<TablerIconsProps, 'size' | 'stroke'> {
  icon: (props: TablerIconsProps) => ReactNode;
  size?: string;
  stroke?: string;
  style?: CSSProperties;
}

export default forwardRef<SVGSVGElement, IconProps>(function Icon(
  { icon: TablerIcon, size = '24px', stroke, style, ...rest },
  ref,
) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <TablerIcon
      ref={ref}
      style={{ height: rem(size), width: rem(size), ...style }}
      stroke={stroke && rem(stroke)}
      {...rest}
    />
  );
});
