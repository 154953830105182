import { Marathon } from '@api/marathons.api';

export const formatMarathonOption = (marathon: Marathon) => {
  switch (marathon.name) {
    case 'StaffettaMiTi':
      return { label: 'Staffetta:"Mi&Ti"', value: marathon.name };
    default:
      return { label: marathon.name, value: marathon.name };
  }
};

export const labelMarathon = (val: string) => {
  if (val === 'StaffettaMiTi') {
    return 'Staffetta:"Mi&Ti"';
  }
  switch (val) {
    case 'StaffettaMiTi':
      return 'Staffetta:"Mi&Ti"';
      break;
    case '21kmCarabinieri':
      return '21kmCarabinieri';
      break;
    case 'La10M6C':
      return 'La10M6C';
      break;
    case 'La32K M6C':
      return 'La32K M6C';
      break;
    case 'LaRegina42k':
      return 'LaRegina42k';
      break;
    case 'MezzaMaratona21km':
      return 'MezzaMaratona21km';
      break;
    case 'Strabacino non competitiva':
      return 'Strabacino non competitiva';
      break;
    case 'Strabacino competitiva':
      return 'Strabacino competitiva';
      break;
  }
};

export const labelGender = (gender: string): string => {
  switch (gender) {
    case 'female':
      return 'Femmina';
    case 'male':
      return 'Maschio';
    default:
      return 'Non disponibile';
  }
};
