import { FC, ReactNode } from 'react';

import { Box, Button, Group, Title } from '@mantine/core';

import SearchBar from './searchBar/SearchBar';

interface SearchAddContainerProps {
  title?: string;
  children: ReactNode;
  additionalFilters?: ReactNode;
  onActionButtonClick?: () => void;
  actionButtonLabel?: string;
  searchPlaceholder?: string;
  searchValue?: string;
  onSearchChange?: (newValue: string) => void;
}

const SearchAddContainer: FC<SearchAddContainerProps> = ({
  title,
  additionalFilters,
  onActionButtonClick,
  actionButtonLabel = 'Aggiungi',
  searchPlaceholder,
  searchValue,
  onSearchChange,
  children,
}) => {
  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Box mb="lg">
      {title && (
        <Title order={4} mb="md">
          {title}
        </Title>
      )}
      <Group justify="space-between" mb="md">
        {onSearchChange && (
          <Group style={{ alignItems: 'end' }}>
            <SearchBar
              placeholder={searchPlaceholder || ''}
              value={searchValue}
              onChange={onSearchChange}
            />
            {additionalFilters}
          </Group>
        )}

        {onActionButtonClick && (
          <Button onClick={onActionButtonClick} size="xs">
            {actionButtonLabel}
          </Button>
        )}
      </Group>
      {children}
    </Box>
  );
};

export default SearchAddContainer;
