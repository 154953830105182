import { FC } from 'react';

import { Anchor, Group, Text } from '@mantine/core';
import { IconMail, IconMapPin, IconPhone } from '@tabler/icons-react';

import Icon from '@components/Icon';

import classes from './Footer.module.css';

const Footer: FC = () => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <footer className={classes.shell}>
      <Text className={classes.version} c="#666">
        1.2.0
      </Text>
      <Group py={10} style={{ overflow: 'hidden' }} justify="center" gap="9rem">
        <Text c="#ff0000" fw={600}>
          Sport Power SSDARL
        </Text>
        <Group gap="xs" justify="center">
          <Icon icon={IconMapPin} style={{ color: 'rgb(255,0,0)' }} />
          <Text fw={300} fz="sm" c="white">
            Via Stadio n. 22, Villaverla (VI) - 36030
          </Text>
        </Group>
        <Group gap="xs" justify="center">
          <Icon icon={IconMail} style={{ color: 'rgb(255,0,0)' }} />
          <Anchor href="mailto:info@m6c.it" fw={300} fz="sm">
            info@m6c.it
          </Anchor>
        </Group>
        <Group gap="xs">
          <Icon icon={IconPhone} style={{ color: 'rgb(255,0,0)' }} />
          <Anchor href="tel:0445856245" fw={300} fz="sm">
            +39 0445 856245
          </Anchor>
        </Group>
      </Group>
      <Anchor
        href="https://www.miroirstudio.com/"
        underline="never"
        className={classes.miroir}
        c="#666"
      >
        Developed by Miroirstudio
      </Anchor>
    </footer>
  );
};

export default Footer;
