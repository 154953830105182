import { Navigate, useSearchParams } from 'react-router-dom';

import logo from '@images/logo_M6C.svg';
import { Space } from '@mantine/core';

import useAuth from '@hooks/useAuth';

import LoginForm from '@components/LoginForm';

export default function Login() {
  const { isAuthenticated } = useAuth();
  const [searchParams] = useSearchParams();

  return isAuthenticated ? (
    <Navigate to={searchParams.get('r') || '/'} replace={true} />
  ) : (
    <div style={{ height: '100vh', backgroundColor: '#01336C' }}>
      <Space h={50} />
      <img
        src={logo}
        style={{ display: 'block', maxWidth: 200, margin: '0 auto' }}
        alt="Logo"
      />
      <LoginForm maw={500} mx="auto" mt={30} />
    </div>
  );
}
