import { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '@images/logo_M6C.svg';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  Group,
  PasswordInput,
  Select,
  Space,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import {
  IconAddressBook,
  IconCheck,
  IconLockOpen,
  IconUser,
} from '@tabler/icons-react';

import env from '@config/env';

import { isApiError } from '@api/index';
import { useCreateCompanyUserMutation } from '@api/users.api';

import useScript from '@hooks/useScript';

import Icon from '@components/Icon';

import statesData from '../../data/nation.json';
import { requiredValidator } from '../../utils/forms';
import classes from './RegistrationsCompany.module.css';

export default function RegistrationsCompany() {
  // ==========================================================================
  // General
  // ==========================================================================

  useScript(
    'https://www.google.com/recaptcha/api.js?render=6LezXVcnAAAAAH8fH9hAa-V_K5JFD3tdVJyTz7Lc',
  );

  // ==========================================================================
  // State
  // ==========================================================================

  const [activeStep, setActiveStep] = useState(0);
  const [isPrivacy, setIsPrivacy] = useState<boolean>(false);

  // ==========================================================================
  // Api
  // ==========================================================================

  const [createUser, { isLoading: isLoadingcreateUser }] =
    useCreateCompanyUserMutation();

  // ==========================================================================
  // Form
  // ==========================================================================
  const nextStep = async () => {
    await form.validate();

    if (form.isValid()) {
      setActiveStep((current) => (current < 3 ? current + 1 : current));
    }
  };

  const prevStep = () => {
    setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  const initialValues = {
    name: '',
    vatNumber: '',
    address: '',
    city: '',
    provincie: '',
    cap: '',
    sdiCode: '',
    state: 'Italia',
    email: '',
    phone: '',
    privacy: false,
    password: '',
    passwordConfirm: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      name: (val) => requiredValidator(val),
      vatNumber: (val) =>
        /^[0-9]{11}$/.test(val)
          ? null
          : 'Il numero di partita IVA non è valido',
      address: (val) => requiredValidator(val),
      city: (val) => requiredValidator(val),
      provincie: (val) =>
        /^[a-zA-Z]+$/.test(val) ? null : 'Può contenere solo lettere',
      sdiCode: (val) =>
        val.length >= 7
          ? null
          : 'Il codice SDI deve essere lungo almeno 7 caratteri',
      state: (val) => requiredValidator(val),
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email invalida'),
      phone: (val) => requiredValidator(val),
      privacy: (val) =>
        val === false ? 'Impossibile proseguire. Accettare la privacy' : null,
      password: (val) => requiredValidator(val),
      passwordConfirm: (val, values) =>
        val !== values.password ? 'Le password non corrispondono.' : null,
    },
  });

  const selectNationItems = statesData.map((item) => ({
    value: item.name,
    label: item.name,
  }));

  const togglePrivacyCheck = (val: boolean) => {
    setIsPrivacy(val);
    form.setValues({ privacy: val });
  };

  const companyRegister = async (
    values: typeof initialValues,
    token?: string,
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { privacy, passwordConfirm, ...valuesWithoutPrivacy } = values;

      await createUser({
        ...valuesWithoutPrivacy,
        recaptchaToken: token!,
      }).unwrap();

      showNotification({
        title: 'Iscrizione riuscita',
        message: 'Riceverai una mail di conferma',
        icon: <Icon icon={IconCheck} />,
        color: 'teal',
      });

      setTimeout(() => {
        window.location.href = `https://m6c.it/`;
      }, 2000);
    } catch (e: unknown) {
      if (isApiError(e)) {
        if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
          prevStep();
        } else {
          form.setErrors({
            general: 'Errore imprevisto, controlla i campi e riprova',
          });
        }
      }
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    if (env.MODE === 'production') {
      // @ts-ignore
      window['grecaptcha'].ready(function () {
        // @ts-ignore
        window['grecaptcha']
          .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
          .then(async (token: string) => {
            companyRegister(values, token);
          });
      });
    } else {
      companyRegister(values);
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <>
      <div className={classes.container}>
        <Container size="md" py={'4rem'}>
          <Group justify="space-between">
            <Button>
              <Link to="https://m6c.it/" className={classes.linkStyle}>
                Torna al sito
              </Link>
            </Button>
            <Title c="white" visibleFrom="sm">
              Registrazione società M6C
            </Title>

            <img src={logo} alt="logo" style={{ width: '10rem' }} />
          </Group>
          <div className={classes.itemRegistration}>
            <form
              onSubmit={form.onSubmit((values) => {
                onSubmit(values);
              })}
            >
              <Title c="#01336C" order={3} hiddenFrom="sm">
                Registrazione società
              </Title>

              <Stepper active={activeStep}>
                <Stepper.Step label="Inserimento dati" description="Iscrizione">
                  <Grid>
                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Group>
                        <Icon icon={IconUser} />
                        <Text fz="16px" fw={600}>
                          Informazioni di base
                        </Text>
                      </Group>
                    </Grid.Col>
                    {/* ROW - name */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <TextInput
                        required
                        label="Nome:"
                        {...form.getInputProps('name')}
                      />
                    </Grid.Col>
                    {/* ROW - vatNumber */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <TextInput
                        required
                        label="P.Iva o CF:"
                        {...form.getInputProps('vatNumber')}
                      />
                    </Grid.Col>
                    {/* ROW - email */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        required
                        label="Email:"
                        {...form.getInputProps('email')}
                      />
                    </Grid.Col>
                    {/* ROW - phonenumber */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        required
                        label="Telefono:"
                        {...form.getInputProps('phone')}
                      />
                    </Grid.Col>
                    {/* ROW - vatNumber */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        required
                        label="Codice SDI:"
                        {...form.getInputProps('sdiCode')}
                        minLength={7}
                        maxLength={7}
                      />
                    </Grid.Col>
                    <Space h="lg" />
                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Divider />
                      <Space h="md" />
                      <Group>
                        <Icon icon={IconAddressBook} />
                        <Text fz="16px" fw={600}>
                          Indirizzo
                        </Text>
                      </Group>
                    </Grid.Col>
                    {/* ROW - state */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Select
                        required
                        label="Stato:"
                        searchable
                        {...form.getInputProps('state')}
                        data={selectNationItems}
                      />
                    </Grid.Col>
                    {/* ROW - province */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <TextInput
                        required
                        label="Provincia (SIGLA):"
                        {...form.getInputProps('provincie')}
                        maxLength={2}
                      />
                    </Grid.Col>
                    {/* ROW - city */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        required
                        label="Città:"
                        {...form.getInputProps('city')}
                      />
                    </Grid.Col>
                    {/* ROW - address */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput
                        required
                        label="Indirizzo:"
                        {...form.getInputProps('address')}
                      />
                    </Grid.Col>
                    {/* ROW - cap */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>
                      <TextInput label="Cap:" {...form.getInputProps('cap')} />
                    </Grid.Col>
                    <Space h="lg" />
                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Divider />
                      <Space h="md" />
                      <Group>
                        <Icon icon={IconLockOpen} />
                        <Text fz="16px" fw={600}>
                          Password
                        </Text>
                      </Group>
                    </Grid.Col>
                    {/* ROW - address */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <PasswordInput
                        required
                        label="Password:"
                        {...form.getInputProps('password')}
                      />
                    </Grid.Col>
                    {/* ROW - address */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <PasswordInput
                        required
                        label="Conferma password:"
                        {...form.getInputProps('passwordConfirm')}
                      />
                    </Grid.Col>
                  </Grid>
                </Stepper.Step>
                <Stepper.Step label="Riepilogo dati" description="Controllo">
                  <Grid>
                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Group>
                        <Icon icon={IconUser} />
                        <Text fz="16px" fw={600}>
                          Informazioni di base
                        </Text>
                      </Group>
                    </Grid.Col>
                    {/* ROW - name */}
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Nome:
                      </Text>
                      <Text fz={'14px'}>{form.values.name || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Partita iva:
                      </Text>
                      <Text fz={'14px'}>{form.values.vatNumber || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Email:
                      </Text>
                      <Text fz={'14px'}>{form.values.email || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Telefono:
                      </Text>
                      <Text fz={'14px'}>{form.values.phone || '-'}</Text>
                    </Grid.Col>

                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Divider />
                      <Space h="md" />
                      <Group>
                        <Icon icon={IconAddressBook} />
                        <Text fz="16px" fw={600}>
                          Indirizzo
                        </Text>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Stato:
                      </Text>
                      <Text fz={'14px'}>{form.values.state || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Provincia:
                      </Text>
                      <Text fz={'14px'}>{form.values.provincie || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Città:
                      </Text>
                      <Text fz={'14px'}>{form.values.city || '-'}</Text>
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 6 }}>
                      <Text fz={'15px'} fw={500}>
                        Indirizzo:
                      </Text>
                      <Text fz={'14px'}>{form.values.address || '-'}</Text>
                    </Grid.Col>
                    {form.values.cap !== '' && (
                      <Grid.Col span={6}>
                        <Text fz={'15px'} fw={500}>
                          Cap:
                        </Text>
                        <Text fz={'14px'}>{form.values.cap || '-'}</Text>
                      </Grid.Col>
                    )}
                    {/* ================================== */}
                    <Grid.Col span={12}>
                      <Divider />
                      <Space h="md" />
                      <Group>
                        <Icon icon={IconLockOpen} />
                        <Text fz="16px" fw={600}>
                          Password
                        </Text>
                      </Group>
                    </Grid.Col>
                    <Grid.Col span={12}>
                      <Text fz={'15px'} fw={500}>
                        Password impostata correttamente
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Stepper.Step>
              </Stepper>
              <Space h="xl" />

              <Group justify="space-between" mt="xl">
                {activeStep !== 0 && (
                  <>
                    <Button variant="default" onClick={prevStep}>
                      Indietro
                    </Button>
                    <Button type="submit" loading={isLoadingcreateUser}>
                      Registrati
                    </Button>
                  </>
                )}

                {activeStep < 1 && (
                  <>
                    <Group>
                      <Checkbox
                        checked={isPrivacy}
                        {...form.getInputProps('privacy')}
                        onChange={(event) =>
                          togglePrivacyCheck(event.currentTarget.checked)
                        }
                        label={
                          <div className={classes.privacyPolicy}>
                            <span>
                              Autorizzo il trattamento dei miei dati personali
                              &nbsp;
                            </span>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  '<a href="https://www.iubenda.com/privacy-policy/89956701" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy "> privacy policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
                              }}
                            ></div>
                          </div>
                        }
                      />
                    </Group>
                    <Button onClick={nextStep} className={classes.changeWidth}>
                      Riepilogo
                    </Button>
                  </>
                )}
              </Group>
              {form.isValid() && (
                <Text c="red" size="md" mt="md">
                  {form.errors.general}
                </Text>
              )}
            </form>
          </div>
        </Container>
      </div>
    </>
  );
}
