import api from './';
import { SubscribersGender } from './registrations.api';

type PaymentStatus = 'completed' | 'failed' | 'inProgress';
export type RaceLengthType = 'l_32km' | 'l_10km';
export type dataTypeDownload = 'certificate';
export type SubscribersCertificateExpiry = 'valid' | 'expired' | 'approaching';
export type TipologyCardSubscriber = 'fidalCard' | 'runCard';

export interface Subscriber {
  id: number;
  name: string;
  surname: string;
  email: string;
  gender: SubscribersGender;
  birthDate: Date;
  nationality: string;
  city: string;
  provincie: string;
  address: string;
  cap: string | null;
  state: string;
  phone: string;
  raceLength: RaceLengthType | null;
  certificateUploaded: boolean;
  registrationId: number;
  createdAt: Date;
  certificateExpiry: SubscribersCertificateExpiry | null;
  certificateDateExpiry: Date | null;
  companyCode: string | null;
  companyName: string | null;
  cardNumber: string | null;
}

export interface SubscriberResponseCreate {
  subscriberDataWithPayment: SubscriberDataWithPayment[];
  paymentStatus: PaymentStatus | null;
  stripeClientSecret: string | null;
}

export interface SubscriberDataWithPayment {
  id: number;
  name: string;
  surname: string;
  email: string;
  gender: SubscribersGender;
  birthDate: Date;
  nationality: string;
  city: string;
  provincie: string;
  address: string;
  cap: string | null;
  state: string;
  phone: string;
  registrationId: number | null;
  createdAt: Date;
  updatedAt: Date;
  raceLength: RaceLengthType | null;
  certificateExpiry: SubscribersCertificateExpiry | null;
}

export interface SubscriberRequestCreate {
  subscriberDataDto: SubscriberDataDto[] | SubscriberDataDto;
  marathon: string;
  coupon?: string | null;
  teamName?: string | null;
  recaptchaToken?: string;
}

export interface SubscriberDataDto {
  name: string;
  surname: string;
  email: string;
  gender: SubscribersGender | string;
  birthDate: Date | null;
  nationality: string;
  city: string;
  provincie: string;
  address: string;
  cap?: string;
  state: string;
  phone: string;
  raceLength?: RaceLengthType | null;
  certificateExpiry: SubscribersCertificateExpiry | null;
  certificateDateExpiry: Date | null;
  typeCard?: TipologyCardSubscriber | null;
  companyName: string | null;
}

export interface ResponseDownloadDocuments {
  name: string;
  url: string;
}

interface RequestDownloadDocuements {
  id: number;
  body: { type: dataTypeDownload };
}

interface checkFidalRequest {
  cardNumber: string;
}
export interface checkFidalResponse {
  response: string;
  certificateExpiry: SubscribersCertificateExpiry;
  typeCard: TipologyCardSubscriber | string;
}

const subscribersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    registerSubscriber: builder.mutation<
      SubscriberResponseCreate,
      SubscriberRequestCreate
    >({
      query: (body) => ({
        url: `subscribers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subscribers'],
    }),
    uploadImages: builder.mutation<void, FormData>({
      query: (body) => ({
        url: `subscribers/upload-images`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subscribers'],
    }),
    downloadImages: builder.mutation<
      ResponseDownloadDocuments[],
      RequestDownloadDocuements
    >({
      query: ({ body, id }) => ({
        url: `subscribers/download-images/${id}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['subscribers'],
    }),
    checkSubscriptionFidal: builder.query<
      checkFidalResponse,
      checkFidalRequest
    >({
      query: (params) => ({
        url: `subscribers/check-subscription-fidal`,
        params,
      }),
      providesTags: ['subscribers'],
    }),
    updateDateFidal: builder.mutation<string, void>({
      query: () => ({
        url: `subscribers/update-data-fidal`,
        method: 'POST',
      }),
      invalidatesTags: ['subscribers', 'registrations'],
    }),
  }),
});

export const {
  useRegisterSubscriberMutation,
  useUploadImagesMutation,
  useDownloadImagesMutation,
  useLazyCheckSubscriptionFidalQuery,
  useUpdateDateFidalMutation,
} = subscribersApi;
