import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Group, Pagination, Select } from '@mantine/core';

interface PaginationRowProps {
  searchParamsKey?: string;
  page: number;
  pageLength: number;
  totalPages: number;
  onPageChange: (newPage: number) => void;
  onPageLengthChange: (newPageLength: number) => void;
  pageOptions?: number[];
}

const PaginationRow: FC<PaginationRowProps> = ({
  searchParamsKey = '',
  page,
  pageLength,
  totalPages,
  onPageChange,
  onPageLengthChange,
  pageOptions = [5, 10, 20, 50],
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handlePageChange = (newPage: number) => {
    searchParams.set(`page${searchParamsKey}`, newPage.toString());
    setSearchParams(searchParams);
    onPageChange(newPage);
  };

  const handlePageLengthChange = (newPageLength: number) => {
    searchParams.set(`pageLength${searchParamsKey}`, newPageLength.toString());
    setSearchParams(searchParams);

    onPageLengthChange(newPageLength);
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Group justify="space-between" mt="xl">
      {totalPages > 1 && (
        <Pagination
          value={page}
          onChange={handlePageChange}
          total={totalPages}
        />
      )}
      <div></div>
      <Select
        aria-label="Elementi per pagina"
        style={{ width: '4.2em' }}
        data={pageOptions.map((opt) => opt.toString())}
        value={pageLength.toString()}
        onChange={(value) => handlePageLengthChange(+value!)}
        allowDeselect={false}
      />
    </Group>
  );
};

export default PaginationRow;
