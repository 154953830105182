import { Link } from 'react-router-dom';

import {
  Anchor,
  Button,
  Group,
  Paper,
  PaperProps,
  PasswordInput,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { isApiError } from '@api/index';

import useAuth from '@hooks/useAuth';

export default function LoginForm(props: PaperProps) {
  const initialValues = {
    email: '',
    password: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    },
  });

  const { signin, isLoading } = useAuth();

  const onSubmit = async (values: typeof initialValues) => {
    try {
      await signin(values);
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
        } else if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else {
          form.setErrors({
            general: 'Unexpected error. Please try again.',
          });
        }
      }
    }
  };

  return (
    <Paper radius="md" p="xl" withBorder {...props}>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <>
          <TextInput
            required
            label="Email"
            placeholder="example@email.com"
            {...form.getInputProps('email')}
            radius="md"
            mb="sm"
          />
          <PasswordInput
            required
            label="Password"
            placeholder="password"
            {...form.getInputProps('password')}
            radius="md"
          />
          <Text ta="end">
            <Anchor
              to="/password-reset"
              component={Link}
              c="dimmed"
              fz="0.8rem"
            >
              Password dimenticata?
            </Anchor>
          </Text>
        </>

        <Group justify="space-between" mt="xl">
          <Text c="dimmed" fz="0.8rem">
            Non hai un account?{' '}
            <Anchor
              component={Link}
              type="button"
              c="dimmed"
              to="/register-company"
              fz="0.8rem"
            >
              Registrati
            </Anchor>
          </Text>

          <Button type="submit" loading={isLoading}>
            Accedi
          </Button>
        </Group>
        {form.errors.general && (
          <Text c="red" size="xs">
            {form.errors.general}
          </Text>
        )}
      </form>
    </Paper>
  );
}
