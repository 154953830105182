import api from './';
import { Payment } from './payments.api';
import { Subscriber } from './subscribers.api';

export type SortBy = 'name' | 'surname' | 'email';
export type SortOrder = 'asc' | 'desc';

export type SubscribersGender = 'all' | 'male' | 'female';
export type MarathonType =
  | 'La10M6C'
  | 'La32K M6C'
  | '21kmCarabinieri'
  | 'MezzaMaratona21km'
  | 'StaffettaMiTi'
  | 'LaRegina42k'
  | 'Strabacino competitiva'
  | 'Strabacino non competitiva';
export type PaymentStatus = 'all' | 'completed' | 'failed';
export type PackageType = 'bronze' | 'silver' | 'gold';
export type RegistrationStatus = 'rejected' | 'confirmed' | 'pending';
export type DocumentsUploadedType = 'both' | 'certificate' | 'nothing' | 'all';
export type MarathonCategoryType = 'M6C' | 'Strabacino' | 'all';
export interface GenerateResponse {
  data: string;
}

export interface Registration {
  id: number;
  identificationCode: string;
  registrationStatus: RegistrationStatus;
  marathonName: string;
  teamName: string | null;
  paymentId: number | null;
  payment: Payment | null;
  createdAt: Date;
  updatedAt: Date;
  subscribers: Subscriber[];
}

export interface RegistrationsRequestApiQueryParams {
  promotionalPackageId: number;
  params: RegistrationsApiQueryParams;
}

export interface RegistrationsApiQueryParams {
  sortBy?: SortBy;
  sortOrder?: SortOrder;
  searchQuery?: string;
  page?: number;
  pageLength?: number;
  marathon?: MarathonType;
  paymentStatus?: PaymentStatus;
  gender?: SubscribersGender;
  coupon?: string;
  category?: MarathonCategoryType;
}

export interface RegistrationsCountRequestApiQueryParams {
  promotionalPackageId: number;
  params: RegistrationsApiCountParams;
}

export interface RegistrationsApiCountParams {
  searchQuery?: string;
  marathon?: MarathonType;
  gender?: SubscribersGender;
  paymentStatus?: PaymentStatus;
  promotionalPackageId?: number;
  coupon?: string;
  category?: MarathonCategoryType;
}

export interface RegistrationsCount {
  count: number;
}

const excelGenerate = api.injectEndpoints({
  endpoints: (builder) => ({
    getRegistrations: builder.query<
      Registration[],
      RegistrationsApiQueryParams
    >({
      query: (params) => ({
        url: `registrations`,
        params,
      }),
      providesTags: ['registrations'],
    }),

    countRegistrations: builder.query<
      RegistrationsCount,
      RegistrationsApiCountParams
    >({
      query: (params) => ({
        url: `registrations/count`,
        params,
      }),
      providesTags: ['registrations'],
    }),

    getRegistrationsByPacakge: builder.query<
      Registration[],
      RegistrationsRequestApiQueryParams
    >({
      query: ({ promotionalPackageId, params }) => ({
        url: `registrations/promotionalPackage/${promotionalPackageId}`,
        params,
      }),
      providesTags: ['registrations'],
    }),

    countRegistrationsByPacakge: builder.query<
      RegistrationsCount,
      RegistrationsCountRequestApiQueryParams
    >({
      query: ({ promotionalPackageId, params }) => ({
        url: `registrations/count/promotionalPackage/${promotionalPackageId}`,
        params,
      }),
      providesTags: ['registrations'],
    }),

    removeRegistration: builder.mutation<Registration, number>({
      query: (id) => ({
        url: `registrations/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['registrations'],
    }),

    confirmRegistration: builder.mutation<void, number>({
      query: (id) => ({
        url: `registrations/${id}/confirm`,
        method: 'PATCH',
      }),
      invalidatesTags: ['registrations', 'coupons'],
    }),

    rejectRegistration: builder.mutation<void, number>({
      query: (id) => ({
        url: `registrations/${id}/reject`,
        method: 'PATCH',
      }),
      invalidatesTags: ['registrations', 'coupons'],
    }),
  }),
});

export const {
  useGetRegistrationsQuery,
  useRemoveRegistrationMutation,
  useCountRegistrationsQuery,
  useConfirmRegistrationMutation,
  useRejectRegistrationMutation,
  useCountRegistrationsByPacakgeQuery,
  useGetRegistrationsByPacakgeQuery,
} = excelGenerate;
