import { Badge, Button, Card, Group, List, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconBuildingBank, IconCreditCard } from '@tabler/icons-react';

import { PaymentMethods } from '@api/payments.api';
import { DataPromotionalSchema } from '@api/promotionalPackages.api';
import { PackageType } from '@api/registrations.api';

import ModalBankTransferInfo from '@components/ModalBankTransferInfo';

import classes from './CardPromotionalPackages.module.css';

interface CardPromotionalPackagesItemProps {
  schema: DataPromotionalSchema;
  mostPopular: boolean;
  onClick: (paymentMethod: PaymentMethods) => void;
  loadingAction: boolean;
}

export default function CardPromotionalPackages({
  schema,
  mostPopular,
  onClick,
  loadingAction,
}: CardPromotionalPackagesItemProps) {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================

  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleOpenModalBankTransferClick = (
    type: PackageType,
    price: number,
    quantity: number,
  ) => {
    openModal({
      title: <Text>Riepilogo informazioni</Text>,
      children: (
        <ModalBankTransferInfo
          type={type}
          price={price}
          quantity={quantity}
          onClick={() => {
            onClick('bacs');
          }}
        />
      ),
      size: 'lg',
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <Card withBorder radius="md" p="md">
      <Card.Section className={classes.sectionIntro} pt="md">
        <Group>
          <Text fz="lg" fw={600} tt="uppercase">
            {schema.name}{' '}
            <Text span fw={400} tt="none">
              - Valore di{' '}
            </Text>
            <Text span td="line-through" fw={400}>
              {Number((schema.totalPrice_old / 100).toFixed(2)).toLocaleString(
                'it-IT',
                {
                  style: 'currency',
                  currency: 'EUR',
                },
              )}
            </Text>{' '}
            <Text span fw={400} tt="none">
              {' '}
              prezzo speciale di{' '}
              {Number((schema.totalPrice / 100).toFixed(2)).toLocaleString(
                'it-IT',
                {
                  style: 'currency',
                  currency: 'EUR',
                },
              )}
            </Text>
          </Text>
        </Group>
        <Text mt="md">
          E' composto da {schema.data.reduce((acc, p) => acc + p.bibs, 0)}{' '}
          pettorali suddiviso in:
        </Text>
        <List fz="sm">
          {schema.data.map((d, index) => {
            const oldData = schema.data_old.find((old) => old.name === d.name)!;

            return (
              <List.Item key={index}>
                <Group w="100%">
                  {d.name}: {d.bibs} pettorali
                  <Text span>
                    <Text span>prezzo </Text>
                    <Text span td="line-through">
                      {Number((oldData.price! / 100).toFixed(2)).toLocaleString(
                        'it-IT',
                        {
                          style: 'currency',
                          currency: 'EUR',
                        },
                      )}
                    </Text>
                  </Text>
                  <Text span fw={600}>
                    {' '}
                    {oldData?.label}
                  </Text>
                  <Text span fw={600}>
                    {' '}
                    {Number((d.price! / 100).toFixed(2)).toLocaleString(
                      'it-IT',
                      {
                        style: 'currency',
                        currency: 'EUR',
                      },
                    )}
                  </Text>
                </Group>
              </List.Item>
            );
          })}
        </List>
      </Card.Section>

      <Card.Section className={classes.section}>
        <Group gap={7} mt={5}>
          <Badge variant="light" key="pri_tot">
            costo totale:{' '}
            {Number((schema.totalPrice / 100).toFixed(2)).toLocaleString(
              'it-IT',
              {
                style: 'currency',
                currency: 'EUR',
              },
            )}{' '}
          </Badge>
          <Badge variant="light" key="num_pet">
            numero pettorali: {schema.data.reduce((acc, p) => acc + p.bibs, 0)}
          </Badge>
          {mostPopular && (
            <Badge size="sm" variant="light">
              più popolare
            </Badge>
          )}
        </Group>
      </Card.Section>

      <Group mt="xs">
        <Button
          radius="md"
          style={{ flex: 1 }}
          onClick={() => onClick('stripe')}
          loading={loadingAction}
          leftSection={<IconCreditCard />}
        >
          Carta di credito
        </Button>
        <Button
          radius="md"
          style={{ flex: 1 }}
          onClick={() => {
            handleOpenModalBankTransferClick(
              schema.name as PackageType,
              Number((schema.totalPrice / 100).toFixed(2)),
              schema.data.reduce((acc, p) => acc + p.bibs, 0),
            );
          }}
          loading={loadingAction}
          leftSection={<IconBuildingBank />}
        >
          Bonifico bancario
        </Button>
      </Group>
    </Card>
  );
}
