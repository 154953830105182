import useUserRoles from '@hooks/useUserRoles';

import DashboardAdminM6C from '@components/dashboardAdminM6C';
import DashboardCompany from '@components/dashboardCompany';

export default function Dashboard() {
  const roleAdmin = useUserRoles('admin');

  return roleAdmin ? <DashboardAdminM6C /> : <DashboardCompany />;
}
