import api from './';
import { MarathonCategoryType } from './registrations.api';

export interface Marathon {
  id: number;
  name: string;
  price: number;
}

export interface RegistrationsApiQueryParams {
  category?: MarathonCategoryType;
}

const marathonsGenerate = api.injectEndpoints({
  endpoints: (build) => ({
    getMarathons: build.query<Marathon[], RegistrationsApiQueryParams>({
      query: (params) => ({
        url: 'marathons',
        params,
      }),
      providesTags: ['marathons'],
    }),
  }),
});

export const { useGetMarathonsQuery } = marathonsGenerate;
