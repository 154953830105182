import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Group, List, Text, Title } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconGiftCard, IconInfoCircle, IconTrash } from '@tabler/icons-react';

import {
  Coupon,
  CouponsApiQueryParams,
  useCountCouponQuery,
  useFindManyCouponsQuery,
  useRemoveCouponMutation,
} from '@api/coupons.api';
import { isApiError } from '@api/index';
import { SortBy, SortOrder } from '@api/registrations.api';

import CreateUpdateCouponModal from '@components/CreateUpdateCouponModal';
import Icon from '@components/Icon';
import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';
import SortableTable from '@components/sortableTable/SortableTable';

import { dateToDateHourseString } from '../../utils/date';
import { mapGender } from '../../utils/mapData';

export default function CouponPage() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams] = useSearchParams();
  const [filters, setFilters] = useState<CouponsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });
  // ==========================================================================
  // State
  // ==========================================================================

  // ==========================================================================
  // Api
  // ==========================================================================
  // Get All coupons
  const { data = [], isLoading: isLoadingCoupons } = useFindManyCouponsQuery(
    {
      ...filters,
      searchQuery,
    },
    { refetchOnMountOrArgChange: true },
  );

  // Get count coupons
  const {
    data: couponsCount = { count: 0 },
    isLoading: isLoadingCouponsCount,
  } = useCountCouponQuery({
    searchQuery,
  });

  // Remove coupon
  const [removeCoupon, { isLoading: isLoadingRemoveCoupon }] =
    useRemoveCouponMutation();
  // ==========================================================================
  // Form
  // ==========================================================================

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const couponsData = data.map((c) => {
    const data = [
      dateToDateHourseString(c.createdAt),
      c.code,
      c.couponPriceType === 'absolute' ? 'Assoluto' : 'Relativo',
      c.quantity ? c.quantity : 'Illimitato',
      mapGender(c.targetGender),
      dateToDateHourseString(c.startDate),
      c.endDate ? dateToDateHourseString(c.endDate) : '-',
      <Group justify="right">
        <Button
          size="xs"
          leftSection={<Icon icon={IconInfoCircle} />}
          onClick={() => handlerDetail(c)}
        >
          Dettagli
        </Button>
        <Button
          size="xs"
          leftSection={<Icon icon={IconTrash} />}
          onClick={() => handlerRemoveCoupon(c)}
          loading={isLoadingRemoveCoupon}
        >
          Elimina
        </Button>
      </Group>,
    ];
    return {
      key: c.code,
      data,
    };
  });

  const handlerRemoveCoupon = (value: Coupon) => {
    openConfirmModal({
      title: <Title order={3}>Cancella coupon</Title>,
      size: 'lg',
      children: (
        <Text>
          Stai per eliminare definitivamente il coupon <em>{value.code}</em>.
          Sicuro di voler confermare?
        </Text>
      ),
      labels: {
        confirm: 'Conferma eliminazione',
        cancel: 'Annulla',
      },
      confirmProps: { color: '#c00404' },
      onConfirm: async () => {
        try {
          await removeCoupon(value.code).unwrap();
          showNotification({
            title: 'Coupon eliminato',
            message: 'Il coupon è stato correttamente eliminato',
          });
        } catch (e) {
          if (isApiError(e)) {
            if (e.status === 404) {
              showNotification({
                color: 'red',
                title: 'Errore',
                message: `${e.data.message}`,
              });
            } else if (e.status === 405) {
              showNotification({
                color: 'red',
                title: 'Errore',
                message: `${e.data.message}`,
              });
            } else {
              console.error(e);
              showNotification({
                color: 'red',
                title: 'Errore',
                message: `Impossibile eliminare il coupon`,
              });
            }
          }
        }
      },
    });
  };

  const handlerDetail = (value: Coupon) => {
    openModal({
      title: <Title order={3}>Informazioni coupon</Title>,
      children: (
        <>
          <Text>Coupon valido per:</Text>
          <List>
            {value.couponMarathons.map((cm) => (
              <List.Item>
                {cm.marathonId} -{' '}
                {Number(((cm.price || 0) / 100).toFixed(2)).toLocaleString(
                  'it-IT',
                  {
                    style: 'currency',
                    currency: 'EUR',
                  },
                )}
              </List.Item>
            ))}
          </List>
        </>
      ),
      size: 'lg',
    });
  };
  // ==========================================================================
  // Render
  // ==========================================================================
  const totalPages = Math.ceil(couponsCount.count / filters.pageLength!);

  return (
    <Layout
      title="Coupon"
      actionButtons={[
        {
          label: 'Genera coupon',
          onClick: () => {
            openModal({
              title: <Text>Genera coupon</Text>,
              children: <CreateUpdateCouponModal />,
              size: 'md',
            });
          },
          icon: IconGiftCard,
        },
      ]}
    >
      <SearchAddContainer
        additionalFilters={[]}
        searchPlaceholder="Ricerca per coupon"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <SortableTable
          data={couponsData}
          headings={{
            createdAt: 'Data creazione',
            code: 'Coupon',
            couponPriceType: 'Sconto',
            quantity: 'Quantità',
            gender: 'Genere',
            startDate: 'Data inizio',
            endDate: 'Data fine',
            actionsIcon: '',
          }}
          sortableKeys={['couponId']}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as SortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun coupon trovato"
          loading={isLoadingCoupons || isLoadingCouponsCount}
          lastColumnRight
        />
        {couponsData.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </Layout>
  );
}
