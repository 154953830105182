import { ActionIcon, TextInput } from '@mantine/core';
import { IconSearch, IconTrash } from '@tabler/icons-react';

import Icon from '@components/Icon';

import classes from './SearchBar.module.css';

interface SearchBarProps {
  placeholder: string;
  value?: string;
  onChange: (newValue: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  value,
  onChange,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <TextInput
      placeholder={placeholder}
      leftSection={<Icon icon={IconSearch} size="20px" />}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className={classes.textInput}
      size="sm"
      rightSection={
        value !== '' && (
          <ActionIcon onClick={() => onChange('')} size="sm">
            <Icon icon={IconTrash} size="20px" />
          </ActionIcon>
        )
      }
    />
  );
};

export default SearchBar;
