import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import logoExcel from '@images/excel.svg';
import {
  Anchor,
  Badge,
  Box,
  Button,
  Grid,
  Group,
  Select,
  Text,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconBriefcase,
  IconCalendar,
  IconCode,
  IconFileInvoice,
  IconInfoCircle,
  IconMail,
  IconPhone,
  IconRoute,
  IconScan,
  IconUser,
} from '@tabler/icons-react';

import {
  useLazyGenerateExcelStrabacinoNoQuery,
  useLazyGenerateExcelStrabacinoQuery,
} from '@api/excel.api';
import { useGetMarathonsQuery } from '@api/marathons.api';
import {
  MarathonType,
  PaymentStatus,
  Registration,
  RegistrationsApiQueryParams,
  SortBy,
  SortOrder,
  SubscribersGender,
  useCountRegistrationsQuery,
  useGetRegistrationsQuery,
} from '@api/registrations.api';
import {
  dataTypeDownload,
  Subscriber,
  useDownloadImagesMutation,
} from '@api/subscribers.api';

import Icon from '@components/Icon';
import Layout from '@components/layout/Layout';
import PaginationRow from '@components/PaginationRow';
import SearchAddContainer from '@components/SearchAddContainer';
import SortableTable, {
  TableData,
} from '@components/sortableTable/SortableTable';

import { dateToDateString, dateToNormaFormat } from '../utils/date';
import {
  formatMarathonOption,
  labelGender,
  labelMarathon,
} from '../utils/mapLabel';
import { capitalizeString } from '../utils/text';

export default function DashboardAdminStrabacino() {
  // ==========================================================================
  // State
  // ==========================================================================

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<RegistrationsApiQueryParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 50),
    searchQuery: '',
    category: 'Strabacino',
    marathon: searchParams.get('marathon')
      ? (searchParams.get('marathon') as MarathonType)
      : undefined,
    gender: searchParams.get('gender')
      ? (searchParams.get('gender') as SubscribersGender)
      : undefined,
    paymentStatus: searchParams.get('paymentStatus')
      ? (searchParams.get('paymentStatus') as PaymentStatus)
      : undefined,
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  // ==========================================================================
  // Api
  // ==========================================================================

  // Get All subscribers
  const { data = [], isLoading: isLoadingSubscribers } =
    useGetRegistrationsQuery(
      {
        ...filters,
        searchQuery: searchQuery,
      },
      { refetchOnMountOrArgChange: true },
    );

  // Get count subscribers
  const {
    data: subscribersCount = { count: 0 },
    isLoading: isLoadingSubscribersCount,
  } = useCountRegistrationsQuery({
    category: filters.category,
    marathon: filters.marathon,
    gender: filters.gender,
    paymentStatus: filters.paymentStatus,
    searchQuery,
  });

  // Generate excel
  const [
    generateExcelStrabacino,
    { isLoading: isLoadingGenerateExcelStrabacino },
  ] = useLazyGenerateExcelStrabacinoQuery();

  const [
    generateExcelStrabacinoNo,
    { isLoading: isLoadingGenerateExcelStrabacinoNo },
  ] = useLazyGenerateExcelStrabacinoNoQuery();

  // Download documents
  const [downloadImages] = useDownloadImagesMutation();

  // Get marathons
  const { data: marathons = [], isLoading: isLoadingMarathons } =
    useGetMarathonsQuery({ category: filters.category });

  // Map subscribers to table
  const registrations: TableData[] = [];

  data.map((reg: Registration) => {
    reg.subscribers.map((sub: Subscriber) => {
      const dataFormat = dateToDateString(new Date(sub.birthDate));

      const data = [
        dateToNormaFormat(sub.createdAt.toString()),
        sub.name,
        sub.surname,
        sub.email,
        labelMarathon(reg.marathonName),
        labelGender(sub.gender),

        <Group justify="center">
          {sub.certificateDateExpiry === null ? (
            <Box w="24px">-</Box>
          ) : (
            dateToNormaFormat(sub.certificateDateExpiry.toString())
          )}
          <Button
            size="xs"
            leftSection={<Icon icon={IconFileInvoice} />}
            disabled={!sub.certificateUploaded}
            onClick={() => {
              downloadDocuments(sub.id, 'certificate');
            }}
          >
            Scarica
          </Button>
        </Group>,
        reg.payment?.paymentStatus === 'failed' ? (
          <Badge variant="filled" color="#e51f1f" radius="sm">
            fallito
          </Badge>
        ) : reg.payment?.paymentStatus === 'completed' ? (
          <Badge variant="filled" color="#44ce1b" radius="sm">
            completato
          </Badge>
        ) : (
          ''
        ),
        <Group justify="right">
          <Button
            size="xs"
            onClick={async () => {
              openModal({
                title: (
                  <Text>
                    Dettaglio iscritto{' '}
                    <span style={{ fontWeight: 600 }}>
                      {capitalizeString(sub.name)}{' '}
                      {capitalizeString(sub.surname)}
                      {sub.companyName ? ` - ${sub.companyName}` : ''}
                    </span>
                    {sub.cardNumber && (
                      <Text fz={'12px'}>
                        Numero carta:{' '}
                        <span style={{ fontWeight: 600 }}>
                          {sub.cardNumber}
                        </span>{' '}
                      </Text>
                    )}
                  </Text>
                ),
                children: (
                  <>
                    <Grid gutter={25} mt="sx">
                      {/* ROW */}
                      <Grid.Col span={6}>
                        <Group mb="lg">
                          <Icon icon={IconCalendar} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Data di nascita
                            </Text>
                            <Text size="sm">{dataFormat} </Text>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconRoute} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} fz="sm">
                              Tappa
                            </Text>
                            <Text size="sm">
                              {labelMarathon(reg.marathonName)}
                            </Text>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconUser} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Genere
                            </Text>
                            <Text fz="sm">{labelGender(sub.gender)}</Text>
                          </div>
                        </Group>
                        {reg.payment?.couponCode && (
                          <Group mb="lg">
                            <Icon icon={IconCode} size="25px" />
                            <div style={{ flex: 1 }}>
                              <Text fw={600} size="sm">
                                Coupon utilizzato
                              </Text>
                              <Text fz="sm">{reg.payment.couponCode}</Text>
                            </div>
                          </Group>
                        )}
                      </Grid.Col>
                      {/* ROW */}
                      <Grid.Col span={6}>
                        <Group mb="lg">
                          <Icon icon={IconPhone} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} fz="sm">
                              Telefono
                            </Text>
                            <Anchor
                              href={`tel:${sub?.phone}`}
                              fz="sm"
                              underline="never"
                              c="#000"
                            >
                              {sub.phone}
                            </Anchor>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconMail} size="25px" />
                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Email
                            </Text>
                            <Anchor
                              href={`mailto:${sub.email}`}
                              size="sm"
                              underline="never"
                              c="#000"
                            >
                              {sub.email}
                            </Anchor>
                          </div>
                        </Group>

                        <Group mb="lg">
                          <Icon icon={IconScan} size="25px" />

                          <div style={{ flex: 1 }}>
                            <Text fw={600} size="sm">
                              Codice identificativo
                            </Text>
                            <Text fz="sm">{reg.identificationCode}</Text>
                          </div>
                        </Group>
                        {reg.payment?.coupons?.promotionalPackages?.users && (
                          <Group mb="lg">
                            <Icon icon={IconBriefcase} size="25px" />
                            <div style={{ flex: 1 }}>
                              <Text fw={600} size="sm">
                                Società
                              </Text>
                              <Text fz="sm">
                                {
                                  reg.payment.coupons.promotionalPackages.users
                                    .name
                                }
                              </Text>
                            </div>
                          </Group>
                        )}
                      </Grid.Col>
                    </Grid>
                  </>
                ),
                size: '40%',
              });
            }}
            leftSection={<Icon icon={IconInfoCircle} />}
          >
            Dettagli
          </Button>
        </Group>,
      ];

      registrations.push({
        key: sub.id,
        data,
      });
    });
  });

  // UseEffect loading
  useEffect(() => {
    if (
      (isLoadingSubscribers ||
        isLoadingSubscribersCount ||
        isLoadingMarathons) &&
      !isLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingSubscribers, isLoadingSubscribersCount]);

  // ==========================================================================
  // Handlers
  // ==========================================================================

  // Generate excel
  const handleGenerateExcel = async (competitiva: boolean) => {
    try {
      const response = competitiva
        ? await generateExcelStrabacino().unwrap()
        : await generateExcelStrabacinoNo().unwrap();
      showNotification({
        color: 'green',
        title: 'Successo',
        message: 'Excel generato con successo',
      });
      window.location.href = response.data;
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Errore',
        message: "Errore nel download dell'excel",
      });
    }
  };

  // Select filter by marathon
  const onSubscribersMarathonChange = (value: string | null) => {
    searchParams.set('marathon', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      marathon: value as MarathonType,
    });
  };

  const marathonFilter = (
    <Select
      allowDeselect={false}
      key="marathon filter"
      value={filters.marathon}
      onChange={onSubscribersMarathonChange}
      placeholder="Filtro percorso"
      data={[
        { label: 'Tutti', value: 'all' },
        ...marathons.map(formatMarathonOption),
      ]}
    />
  );

  // Select filter by gender
  const onSubscribersGenderChange = (value: string | null) => {
    searchParams.set('gender', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      gender: value! as SubscribersGender,
    });
  };

  const genderFilter = (
    <Select
      allowDeselect={false}
      key="category filter"
      value={filters.gender}
      onChange={onSubscribersGenderChange}
      placeholder="Filtro genere"
      data={[
        { label: 'Tutti', value: 'all' },
        { label: 'Maschio', value: 'male' },
        { label: 'Femmina', value: 'female' },
      ]}
    />
  );

  // Select filter by payment
  const onSubscribersPaymentStatusChange = (value: string | null) => {
    searchParams.set('paymentStatus', value!);
    setSearchParams(searchParams);
    setFilters({
      ...filters,
      paymentStatus: value as PaymentStatus,
    });
  };

  const paymentFilter = (
    <Select
      allowDeselect={false}
      style={{ width: '14rem' }}
      key="payment filter"
      value={filters.paymentStatus}
      onChange={onSubscribersPaymentStatusChange}
      placeholder="Filtro pagamento"
      data={[
        { label: 'Tutti', value: 'all' },
        { label: 'Pagamento completato', value: 'completed' },
        { label: 'Pagamento fallito', value: 'failed' },
      ]}
    />
  );

  //Download docuemnts
  const downloadDocuments = async (id: number, type: dataTypeDownload) => {
    try {
      const response = await downloadImages({
        id,
        body: { type },
      }).unwrap();

      for (let i = 0; i < response.length; i++) {
        const { name, url } = response[i];
        const imageResponse = await fetch(url);
        const imageBlob = await imageResponse.blob();
        const imageURL = window.URL.createObjectURL(imageBlob);

        const link = document.createElement('a');
        link.href = imageURL;

        // Set the download attribute to the provided filename
        link.setAttribute('download', name);

        link.click();
        window.URL.revokeObjectURL(imageURL);
      }

      showNotification({
        color: 'green',
        title: 'Successo',
        message: 'Documenti scaricati con successo',
      });
    } catch (e) {
      showNotification({
        color: 'red',
        title: 'Errore',
        message: 'Errore nel download dei documenti',
      });
    }
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  const totalPages = Math.ceil(subscribersCount.count / filters.pageLength!);

  return (
    <Layout
      title="Iscritti Strabacino"
      actionButtons={[
        {
          label: 'Genera Excel NON competitiva',
          onClick: () => {
            handleGenerateExcel(false);
          },
          disabled: isLoadingGenerateExcelStrabacinoNo,
          icon: logoExcel,
        },
        {
          label: 'Genera Excel competitiva',
          onClick: () => {
            handleGenerateExcel(true);
          },
          disabled: isLoadingGenerateExcelStrabacino,
          icon: logoExcel,
        },
      ]}
    >
      <SearchAddContainer
        additionalFilters={[marathonFilter, genderFilter, paymentFilter]}
        searchPlaceholder="Ricerca per nome, cognome o email"
        searchValue={filters.searchQuery}
        onSearchChange={(newValue) =>
          setFilters({ ...filters, searchQuery: newValue })
        }
      >
        <SortableTable
          data={registrations}
          headings={{
            createdAt: 'Data di registrazione',
            nome: 'Nome',
            cognome: 'Cognome',
            email: 'Email',
            percorso: 'Percorso',
            genere: 'Genere',
            certificateExpiry: 'Certificato medico',
            payment: 'Pagamento',
            actionsIcon: '',
          }}
          sortableKeys={['couponId']}
          onSortingChange={(key: string, order: SortOrder) =>
            setFilters({
              ...filters,
              sortBy: key as SortBy,
              sortOrder: order,
            })
          }
          emptyText="Nessun iscritto trovato"
          loading={isLoadingSubscribers || isLoadingSubscribersCount}
        />
        {registrations.length > 0 && (
          <PaginationRow
            page={filters.page!}
            pageLength={filters.pageLength!}
            totalPages={totalPages}
            onPageChange={(newPage) =>
              setFilters({ ...filters, page: newPage })
            }
            onPageLengthChange={(newPageLength) =>
              setFilters({ ...filters, pageLength: newPageLength, page: 1 })
            }
          />
        )}
      </SearchAddContainer>
    </Layout>
  );
}
