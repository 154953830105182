import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Box, Button, Group, Title } from '@mantine/core';
import { IconChevronLeft, TablerIconsProps } from '@tabler/icons-react';

import { Coupon } from '@api/coupons.api';

import Footer from '@components/footer/Footer';
import Header from '@components/header/Header';
import Icon from '@components/Icon';

import classes from './Layout.module.css';

interface LayoutProp {
  children?: ReactNode;
  title?: string;
  dataCoupons?: Coupon[];
  actionButtons?: ActionButton[];
  backLink?: { title: string; to: string };
}

interface ActionButton {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  icon: string | ((props: TablerIconsProps) => ReactNode);
}
const Layout: FC<LayoutProp> = ({
  children,
  title,
  actionButtons,
  backLink,
}) => {
  // ==========================================================================
  // General
  // ==========================================================================

  // ==========================================================================
  // Render
  // ==========================================================================
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <Header />
      <div style={{ flexGrow: '1' }}>
        {backLink?.title && (
          <Button
            component={Link}
            to={backLink.to}
            leftSection={<Icon icon={IconChevronLeft} />}
            ml={'4rem'}
            mt={'2rem'}
          >
            {backLink.title}
          </Button>
        )}
        <Box m={backLink ? '2rem 4rem 4rem 4rem' : '4rem'}>
          <Group justify="space-between" align="center" mb="lg">
            {title && <Title className={classes.title}>{title}</Title>}

            <Group justify="right">
              {actionButtons &&
                actionButtons.map((button, index) => (
                  <Button
                    key={index}
                    leftSection={
                      // Verifica se è stata fornita un'icona come URL o componente
                      typeof button.icon === 'string' ? (
                        <img
                          src={button.icon}
                          alt="icon"
                          style={{ height: '1.4rem' }}
                        />
                      ) : (
                        <Icon icon={button.icon} />
                      )
                    }
                    size="md"
                    onClick={button.onClick}
                    style={{ fontSize: '0.8rem', padding: '0 1.5rem' }}
                    disabled={button.disabled}
                    loading={button.disabled}
                  >
                    {button.label}
                  </Button>
                ))}
            </Group>
          </Group>
          {children}
        </Box>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
