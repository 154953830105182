import { closeAllModals } from '@mantine/modals';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import env from '@config/env';

import PaymentForm from './PaymentForm';

interface ModalPaymentStripeProps {
  paymentClientSecret: string;
  price: number;
  onSuccess?: () => void;
}
const stripePromise = loadStripe(env.STRIPE_PUBLIC_KEY);
export default function ModalPaymentStripe({
  paymentClientSecret,
  price,
  onSuccess,
}: ModalPaymentStripeProps) {
  return (
    <>
      {paymentClientSecret && (
        <Elements
          options={{
            clientSecret: paymentClientSecret,
            appearance: {
              theme: 'stripe',
            },
          }}
          stripe={stripePromise}
        >
          <PaymentForm
            total={price}
            onSuccess={() => {
              closeAllModals();
              onSuccess?.();
            }}
          />
        </Elements>
      )}
    </>
  );
}
