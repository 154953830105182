import { Outlet } from 'react-router-dom';

import { AppShell, Container } from '@mantine/core';

export default function Root() {
  return (
    <AppShell>
      <AppShell.Main>
        <Container size="100%" p={0}>
          <Outlet />
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
