import { PaymentStatus } from '@api/payments.api';
import { PackageType } from '@api/registrations.api';

const dataPromotionalPackages = [
  {
    name: 'bronze',
    data: [
      { name: 'La10M6C', price: 1750, bibs: 10 },
      { name: 'MezzaMaratona21km', price: 2800, bibs: 10 },
      { name: 'La32K M6C', price: 3500, bibs: 5 },
      { name: 'LaRegina42k', price: 2000, bibs: 2 },
      { name: 'StaffettaMiTi', price: 5250, bibs: 2 },
    ],
    totalPrice: 77500,
  },
  {
    name: 'silver',
    data: [
      { name: 'La10M6C', price: 1250, bibs: 15 },
      { name: 'MezzaMaratona21km', price: 2000, bibs: 20 },
      { name: 'La32K M6C', price: 2500, bibs: 10 },
      { name: 'LaRegina42k', price: 2000, bibs: 5 },
      { name: 'StaffettaMiTi', price: 3750, bibs: 5 },
    ],
    totalPrice: 112500,
  },
  {
    name: 'gold',
    data: [
      { name: 'La10M6C', price: 875, bibs: 30 },
      { name: 'MezzaMaratona21km', price: 1400, bibs: 40 },
      { name: 'La32K M6C', price: 1750, bibs: 20 },
      { name: 'LaRegina42k', price: 2000, bibs: 10 },
      { name: 'StaffettaMiTi', price: 2625, bibs: 10 },
    ],
    totalPrice: 163500,
  },
];

export const mapDataLabelPaymentStatus = (status: PaymentStatus): string => {
  return status === 'completed'
    ? 'Confermato'
    : status === 'inProgress'
      ? 'In attesa'
      : 'Rifiutato';
};

export const getTotalPriceFromPPName = (
  packageName: PackageType,
): number | null => {
  const pp = dataPromotionalPackages.find((pkg) => pkg.name === packageName);
  return pp ? pp.totalPrice : null;
};
