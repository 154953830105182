import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import './styles/global.css';

import { H } from 'highlight.run';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { Center, Loader, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';

import { useGetCurrentUserQuery } from '@api/auth.api';

import { setCredentials } from '@slices/auth.slice';

import router from '@routes/router';

import theme from './styles/theme';

export default function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { data: user, isLoading: isLoadingGetUser } = useGetCurrentUserQuery();

  // Get user when page is reloaded
  useEffect(() => {
    if (!isLoadingGetUser) {
      if (user) {
        H.identify(user.email, {
          id: user.id,
          name: user.name,
        });

        dispatch(setCredentials({ user }));
      }
      setIsLoading(false);
    }
  }, [dispatch, user, isLoadingGetUser]);

  return (
    <MantineProvider theme={theme} forceColorScheme="light">
      <Notifications />
      <ModalsProvider>
        {isLoading ? (
          <Center h="100vh">
            <Loader />
          </Center>
        ) : (
          <RouterProvider router={router} />
        )}
      </ModalsProvider>
    </MantineProvider>
  );
}
